export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** Time resolver */
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type CognitoIdentityCredential = {
  __typename?: 'CognitoIdentityCredential';
  identityId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  logins?: Maybe<Scalars['String']>;
};

export type Dokter = {
  __typename?: 'Dokter';
  /** id dokter */
  id?: Maybe<Scalars['Int']>;
  /** nama dokter */
  nama?: Maybe<Scalars['String']>;
  /** email dokter */
  email?: Maybe<Scalars['String']>;
};

export type DokterData = {
  /** nama dokter */
  nama?: Maybe<Scalars['String']>;
  /** email dokter */
  email?: Maybe<Scalars['String']>;
};

export type DokterList = {
  __typename?: 'DokterList';
  /** daftar dokter */
  list?: Maybe<Array<Maybe<Dokter>>>;
  /** total dokter yg ada */
  count?: Maybe<Scalars['Int']>;
};

export type DokumenRekamMedis = {
  __typename?: 'DokumenRekamMedis';
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['Time']>;
  lastUpdatedAt?: Maybe<Scalars['Time']>;
  creatorId?: Maybe<Scalars['Int']>;
  creatorName?: Maybe<Scalars['String']>;
  idPasien?: Maybe<Scalars['Int']>;
  namaPasien?: Maybe<Scalars['String']>;
  noRm?: Maybe<Scalars['String']>;
  tanggalLahir?: Maybe<Scalars['Date']>;
  /** kode kunjungan */
  idKunjungan?: Maybe<Scalars['Int']>;
  unit?: Maybe<Unit>;
  waktuKunjungan?: Maybe<Scalars['Time']>;
  /** id form pada form-selector */
  idForm?: Maybe<Scalars['String']>;
  /** nama form */
  nama?: Maybe<Scalars['String']>;
  /** judul dari form pada kunjungan tersebut (co: saat 1 form yang sama ditambahkan beberapa kali) */
  judul?: Maybe<Scalars['String']>;
  /** kategori form */
  kategori?: Maybe<Scalars['String']>;
  /** editing history */
  updateHistory?: Maybe<Array<Maybe<UpdateHistory>>>;
  /** json string */
  payload?: Maybe<Scalars['String']>;
};

export type DokumenRekamMedisData = {
  id: Scalars['String'];
  idPasien?: Maybe<Scalars['Int']>;
  creatorId?: Maybe<Scalars['Int']>;
  creatorName?: Maybe<Scalars['String']>;
  namaPasien?: Maybe<Scalars['String']>;
  tanggalLahir?: Maybe<Scalars['Date']>;
  noRm?: Maybe<Scalars['String']>;
  idKunjungan?: Maybe<Scalars['Int']>;
  unit?: Maybe<Unit>;
  waktuKunjungan?: Maybe<Scalars['Date']>;
  idForm?: Maybe<Scalars['String']>;
  nama?: Maybe<Scalars['String']>;
  judul?: Maybe<Scalars['String']>;
  kategori?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
};

/** filter pencarian dokumen rekam medis */
export type DokumenRekamMedisFilter = {
  noRm?: Maybe<Scalars['String']>;
  idKunjungan?: Maybe<Scalars['Int']>;
};

export type DokumenRekamMedisList = {
  __typename?: 'DokumenRekamMedisList';
  /** daftar dokumen rekam medis */
  items?: Maybe<Array<Maybe<DokumenRekamMedis>>>;
  /** total jumlah dokumen rekam medis */
  count?: Maybe<Scalars['Int']>;
};

/** atribut yang digunakan untuk sorting dokumen */
export enum DokumenSortBy {
  CreatedAt = 'createdAt',
  Nama = 'nama',
  Kategori = 'kategori'
}

export type Icd10 = {
  __typename?: 'ICD10';
  /** id icd */
  id?: Maybe<Scalars['String']>;
  /** kode icd */
  code?: Maybe<Scalars['String']>;
  /** nama penyakit */
  name?: Maybe<Scalars['String']>;
};

export enum JenisKelamin {
  /** Laki laki */
  L = 'L',
  /** Perempuan */
  P = 'P'
}

export type JumlahPasien = {
  __typename?: 'JumlahPasien';
  poli?: Maybe<Scalars['String']>;
  dokter?: Maybe<Scalars['String']>;
  spesialis?: Maybe<Scalars['String']>;
  tanggal?: Maybe<Scalars['String']>;
  pasienselesai?: Maybe<Scalars['Int']>;
  pasienbatal?: Maybe<Scalars['Int']>;
  pasienlama?: Maybe<Scalars['Int']>;
  pasienbaru?: Maybe<Scalars['Int']>;
  klasifikasi_bpjs?: Maybe<Scalars['Int']>;
  klasifikasi_pribadi?: Maybe<Scalars['Int']>;
  klasifikasi_rekanan?: Maybe<Scalars['Int']>;
};

export enum KelasUnit {
  Lt1Khusus = 'Lt1Khusus',
  Ugd = 'UGD',
  Lt2Khusus = 'Lt2Khusus',
  Lt2Umum = 'Lt2Umum',
  Lt2Kebidanan = 'Lt2Kebidanan',
  Lt3Umum = 'Lt3Umum',
  Lt3Covid19 = 'Lt3Covid19'
}

export type Kunjungan = {
  __typename?: 'Kunjungan';
  /** id kunjungan */
  id?: Maybe<Scalars['Int']>;
  /** nomor rekam medis pasien */
  noRm?: Maybe<Scalars['String']>;
  /** identitas sementara pasien */
  namaPasien?: Maybe<Scalars['String']>;
  /** waktu kunjungan pasien */
  waktuKunjungan?: Maybe<Scalars['Time']>;
  /** waktu finalisasi pasien */
  waktuFinalisasi?: Maybe<Scalars['Time']>;
  /** informasi pasien */
  pasien?: Maybe<Pasien>;
  /** status pasien sudah difinalisasi atau belum */
  status?: Maybe<StatusKunjungan>;
  /** nama perusahaan dari pasien */
  namaPerusahaan?: Maybe<Scalars['String']>;
  /** jaminan pasien yang digunakan */
  jaminan?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** klasifikasi pasien */
  kelas?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** kunjungan baru/lama */
  pasienStatus?: Maybe<Status>;
  /** kasus baru/lama */
  kasusStatus?: Maybe<Status>;
  /** unit yang digunakan */
  unit?: Maybe<Unit>;
  /** dpjp yang bertugas */
  dpjp?: Maybe<Dokter>;
  /** nomor njm */
  njm?: Maybe<Scalars['String']>;
  /** nama diagnosa */
  diagnosa?: Maybe<Scalars['String']>;
  /** nama perujuk */
  perujuk?: Maybe<Scalars['String']>;
  /** instansi yang dirujuk */
  dirujuk?: Maybe<Scalars['String']>;
  /** status finalisasi */
  statusFinalisasi?: Maybe<StatusFinalisasi>;
  /** icd 9 */
  icd9?: Maybe<Array<Maybe<Tindakan>>>;
  /** icd x */
  icdx?: Maybe<Array<Maybe<Icd10>>>;
  /** informasi spesifik */
  informasiSpesifik?: Maybe<Scalars['String']>;
  /** catatan */
  notes?: Maybe<Scalars['String']>;
  /** no antrian */
  noAntrian?: Maybe<Scalars['String']>;
  /** kelas unit */
  kelasUnit?: Maybe<KelasUnit>;
  /** status pada pemeriksaan */
  statusPemeriksaan?: Maybe<Array<Maybe<StatusPemeriksaan>>>;
  /** icd9 freetext */
  icd9hipotesis?: Maybe<Scalars['String']>;
  /** icdx freetext */
  icdxhipotesis?: Maybe<Scalars['String']>;
  /** schedule date */
  scheduleDate?: Maybe<Scalars['Date']>;
  /** schedule time */
  scheduleTime?: Maybe<KunjunganTime>;
  /** transfer dari */
  transferFrom?: Maybe<Scalars['String']>;
};

export type KunjunganData = {
  /** nomor rekam medis pasien */
  noRm?: Maybe<Scalars['String']>;
  /** identitas sementara pasien */
  namaPasien?: Maybe<Scalars['String']>;
  /** waktu kunjungan pasien */
  waktuKunjungan?: Maybe<Scalars['Time']>;
  /** waktu finalisasi pasien */
  waktuFinalisasi?: Maybe<Scalars['Time']>;
  /** id dari pasien yang terdaftar */
  pasienId?: Maybe<Scalars['Int']>;
  /** nama perusahaan dari pasien */
  namaPerusahaan?: Maybe<Scalars['String']>;
  /** jaminan pasien yang digunakan */
  jaminan?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** klasifikasi pasien */
  kelas?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** kunjungan baru/lama */
  pasienStatus?: Maybe<Status>;
  /** kasus baru/lama */
  kasusStatus?: Maybe<Status>;
  /** unit yang digunakan */
  unit?: Maybe<Unit>;
  /** id dpjp yang bertugas */
  dpjpId?: Maybe<Scalars['Int']>;
  /** nomor njm */
  njm?: Maybe<Scalars['String']>;
  /** nama diagnosa */
  diagnosa?: Maybe<Scalars['String']>;
  /** nama perujuk */
  perujuk?: Maybe<Scalars['String']>;
  /** instansi yang dirujuk */
  dirujuk?: Maybe<Scalars['String']>;
  /** status finalisasi */
  statusFinalisasi?: Maybe<StatusFinalisasi>;
  /** icd 9 */
  icd9?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** icd x */
  icdx?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** informasi spesifik */
  informasiSpesifik?: Maybe<Scalars['String']>;
  /** catatan */
  notes?: Maybe<Scalars['String']>;
  /** no antrian */
  noAntrian?: Maybe<Scalars['String']>;
  /** kelas unit */
  kelasUnit?: Maybe<KelasUnit>;
  /** status pada pemeriksaan */
  statusPemeriksaan?: Maybe<Array<Maybe<StatusPemeriksaan>>>;
  /** icd9 freetext */
  icd9hipotesis?: Maybe<Scalars['String']>;
  /** icdx freetext */
  icdxhipotesis?: Maybe<Scalars['String']>;
  /** schedule date */
  scheduleDate?: Maybe<Scalars['Date']>;
  /** schedule time */
  scheduleTime?: Maybe<KunjunganTime>;
};

export type KunjunganList = {
  __typename?: 'KunjunganList';
  /** daftar kunjungan */
  list?: Maybe<Array<Maybe<Kunjungan>>>;
  /** total kunjungan yg ada */
  count?: Maybe<Scalars['Int']>;
};

export type KunjunganMutation = {
  __typename?: 'KunjunganMutation';
  /** tambah kunjungan baru */
  add?: Maybe<Kunjungan>;
  /** edit kunjungan */
  edit?: Maybe<Kunjungan>;
  /** finalisasi kunjungan */
  finalisasi?: Maybe<Kunjungan>;
  /** transfer pasien */
  transfer?: Maybe<Kunjungan>;
  /** batal finalisasi kunjungan */
  batalFinalisasi?: Maybe<Kunjungan>;
  /** selesai finalisasi kunjungan dan coding */
  coding?: Maybe<Kunjungan>;
  /** batal kunjungan */
  batal?: Maybe<Kunjungan>;
  /** batal kunjungan */
  register?: Maybe<Kunjungan>;
};

export type KunjunganMutationAddArgs = {
  data: KunjunganData;
  pasienData?: Maybe<PasienData>;
};

export type KunjunganMutationEditArgs = {
  id: Scalars['Int'];
  data: KunjunganData;
  pasienData?: Maybe<PasienData>;
  selesaiKunjungan?: Maybe<Scalars['Boolean']>;
};

export type KunjunganMutationFinalisasiArgs = {
  id: Scalars['Int'];
  data: KunjunganData;
  pasienData?: Maybe<PasienData>;
};

export type KunjunganMutationTransferArgs = {
  id: Scalars['Int'];
  data: KunjunganData;
  pasienData?: Maybe<PasienData>;
  unit: Unit;
};

export type KunjunganMutationBatalFinalisasiArgs = {
  id: Scalars['Int'];
};

export type KunjunganMutationCodingArgs = {
  id: Scalars['Int'];
  data: KunjunganData;
};

export type KunjunganMutationBatalArgs = {
  id: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
};

export type KunjunganMutationRegisterArgs = {
  id: Scalars['Int'];
};

export type KunjunganPasien = {
  __typename?: 'KunjunganPasien';
  /** data kunjungan */
  kunjungan?: Maybe<Kunjungan>;
  /** data pasien */
  pasien?: Maybe<Pasien>;
};

export type KunjunganQuery = {
  __typename?: 'KunjunganQuery';
  /** daftar kunjungan */
  list?: Maybe<KunjunganList>;
  /** informasi kunjungan berdasarkan id kunjungan */
  byId?: Maybe<Kunjungan>;
};

export type KunjunganQueryListArgs = {
  keyword?: Maybe<Scalars['String']>;
  unit?: Maybe<Unit>;
  dpjpId?: Maybe<Scalars['Int']>;
  noRm?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<StatusKunjungan>>>;
  statusPemeriksaan?: Maybe<Array<Maybe<StatusPemeriksaan>>>;
  kelasUnit?: Maybe<KelasUnit>;
  terjadwal?: Maybe<Scalars['Boolean']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<KunjunganSortBy>;
  sortDesc?: Maybe<Scalars['Boolean']>;
};

export type KunjunganQueryByIdArgs = {
  id: Scalars['Int'];
};

/** atribut yang digunakan untuk sorting kunjungan */
export enum KunjunganSortBy {
  NamaPasien = 'namaPasien',
  WaktuKunjungan = 'waktuKunjungan',
  NoRm = 'noRm',
  NoAntrian = 'noAntrian'
}

export enum KunjunganTime {
  Pagi = 'Pagi',
  Sore = 'Sore'
}

export type KunjunganUpdater = {
  __typename?: 'KunjunganUpdater';
  /** id pengguna yang mengupdate kunjungan */
  id?: Maybe<Scalars['Int']>;
  /** nama pengguna yang mengupdate kunjungan */
  nama?: Maybe<Scalars['String']>;
  /** tanggal dirubah */
  date?: Maybe<Scalars['Date']>;
  /** keterangan */
  description?: Maybe<Scalars['String']>;
};

export type LaporanBatal = {
  __typename?: 'LaporanBatal';
  tanggal?: Maybe<Scalars['String']>;
  nama_pasien?: Maybe<Scalars['String']>;
  no_rm?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  dokter?: Maybe<Scalars['String']>;
  umur?: Maybe<Scalars['String']>;
  jam_masuk?: Maybe<Scalars['String']>;
  jam_pembatalan?: Maybe<Scalars['String']>;
  alasan?: Maybe<Scalars['String']>;
  perawat?: Maybe<Scalars['String']>;
};

export type LaporanMultiUnit = {
  __typename?: 'LaporanMultiUnit';
  nama_pasien?: Maybe<Scalars['String']>;
  umur?: Maybe<Scalars['String']>;
  umurBulan?: Maybe<Scalars['Float']>;
  no_kirs?: Maybe<Scalars['String']>;
  kelamin?: Maybe<Scalars['String']>;
  alamat?: Maybe<Scalars['String']>;
  kota?: Maybe<Scalars['String']>;
  suami?: Maybe<Scalars['String']>;
  klasifikasi?: Maybe<Scalars['String']>;
  tanggal_masuk?: Maybe<Scalars['String']>;
  tanggal_keluar?: Maybe<Scalars['String']>;
  lama_rawat?: Maybe<Scalars['String']>;
  /** # */
  rawat_kelas?: Maybe<Scalars['String']>;
  rujukan_perujuk?: Maybe<Scalars['String']>;
  rujukan_dirujuk?: Maybe<Scalars['String']>;
  kasus?: Maybe<Scalars['String']>;
  diagnosa?: Maybe<Scalars['String']>;
  icdx?: Maybe<Scalars['String']>;
  tindakan?: Maybe<Scalars['String']>;
  berat_bayi?: Maybe<Scalars['String']>;
  panjang_bayi?: Maybe<Scalars['String']>;
  lingkar_kepala_bayi?: Maybe<Scalars['String']>;
  lahir_mati?: Maybe<Scalars['String']>;
  klasifikasi_tindakan?: Maybe<Scalars['String']>;
  dpjp?: Maybe<Scalars['String']>;
  spesialis?: Maybe<Scalars['String']>;
  meninggal?: Maybe<Scalars['String']>;
};

export type LaporanPermintaanLab = {
  __typename?: 'LaporanPermintaanLab';
  nama_pemeriksaan?: Maybe<Scalars['String']>;
  kategori?: Maybe<Scalars['String']>;
  jaminan_bpjs?: Maybe<Scalars['String']>;
  jaminan_pribadi?: Maybe<Scalars['String']>;
  jaminan_asuransi?: Maybe<Scalars['String']>;
  jaminan_lain?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
};

export type LaporanPermintaanRad = {
  __typename?: 'LaporanPermintaanRad';
  nama_pemeriksaan?: Maybe<Scalars['String']>;
  kategori?: Maybe<Scalars['String']>;
  jaminan_bpjs?: Maybe<Scalars['String']>;
  jaminan_pribadi?: Maybe<Scalars['String']>;
  jaminan_asuransi?: Maybe<Scalars['String']>;
  jaminan_lain?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
};

export type LaporanPoli = {
  __typename?: 'LaporanPoli';
  tanggal?: Maybe<Scalars['String']>;
  nama_pasien?: Maybe<Scalars['String']>;
  no_kirs?: Maybe<Scalars['String']>;
  dokter?: Maybe<Scalars['String']>;
  spesialis?: Maybe<Scalars['String']>;
  rujukan_perujuk?: Maybe<Scalars['String']>;
  rujukan_dirujuk?: Maybe<Scalars['String']>;
  umur?: Maybe<Scalars['String']>;
  tanggal_lahir?: Maybe<Scalars['String']>;
  kelamin?: Maybe<Scalars['String']>;
  klasifikasi?: Maybe<Scalars['String']>;
  kunjungan?: Maybe<Scalars['String']>;
  kasus?: Maybe<Scalars['String']>;
  diagnosa?: Maybe<Scalars['String']>;
  icd?: Maybe<Scalars['String']>;
};

export type LaporanQuery = {
  __typename?: 'LaporanQuery';
  jumlahPasien?: Maybe<Array<Maybe<JumlahPasien>>>;
  registerUgd?: Maybe<Array<Maybe<RegisterUgd>>>;
  registerOk?: Maybe<Array<Maybe<RegisterOk>>>;
  registerVk?: Maybe<Array<Maybe<RegisterVk>>>;
  registerRawatInap?: Maybe<Array<Maybe<RegisterRawatInap>>>;
  registerIcu?: Maybe<Array<Maybe<RegisterIcu>>>;
  registerHcu?: Maybe<Array<Maybe<RegisterHcu>>>;
  registerPerina?: Maybe<Array<Maybe<RegisterPerina>>>;
  registerPermintaanLab?: Maybe<Array<Maybe<RegisterPermintaanLab>>>;
  registerPermintaanRad?: Maybe<Array<Maybe<RegisterPermintaanRad>>>;
  utilisasiPoli?: Maybe<Array<Maybe<UtilisasiPoli>>>;
  laporanPoli?: Maybe<Array<Maybe<LaporanPoli>>>;
  laporanUgd?: Maybe<Array<Maybe<LaporanUgd>>>;
  laporanMultiUnit?: Maybe<Array<Maybe<LaporanMultiUnit>>>;
  laporanBatal?: Maybe<Array<Maybe<LaporanBatal>>>;
  laporanZero?: Maybe<Array<Maybe<LaporanZero>>>;
  laporanPermintaanLab?: Maybe<Array<Maybe<LaporanPermintaanLab>>>;
  laporanPermintaanRad?: Maybe<Array<Maybe<LaporanPermintaanRad>>>;
};

export type LaporanQueryJumlahPasienArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanQueryRegisterUgdArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanQueryRegisterOkArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanQueryRegisterVkArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanQueryRegisterRawatInapArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanQueryRegisterIcuArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanQueryRegisterHcuArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanQueryRegisterPerinaArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanQueryRegisterPermintaanLabArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanQueryRegisterPermintaanRadArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanQueryUtilisasiPoliArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanQueryLaporanPoliArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanQueryLaporanUgdArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanQueryLaporanMultiUnitArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanQueryLaporanBatalArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanQueryLaporanZeroArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanQueryLaporanPermintaanLabArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanQueryLaporanPermintaanRadArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type LaporanUgd = {
  __typename?: 'LaporanUgd';
  tanggal?: Maybe<Scalars['String']>;
  jam_masuk?: Maybe<Scalars['String']>;
  nama_pasien?: Maybe<Scalars['String']>;
  no_kirs?: Maybe<Scalars['String']>;
  dokter?: Maybe<Scalars['String']>;
  spesialis?: Maybe<Scalars['String']>;
  rujukan_perujuk?: Maybe<Scalars['String']>;
  rujukan_dirujuk?: Maybe<Scalars['String']>;
  umur?: Maybe<Scalars['String']>;
  umurBulan?: Maybe<Scalars['Float']>;
  kelamin?: Maybe<Scalars['String']>;
  klasifikasi?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  kecelakaan?: Maybe<Scalars['String']>;
  rawat_kelas?: Maybe<Scalars['String']>;
  kunjungan?: Maybe<Scalars['String']>;
  kasus?: Maybe<Scalars['String']>;
  diagnosa?: Maybe<Scalars['String']>;
  icd?: Maybe<Scalars['String']>;
  pelayanan?: Maybe<Scalars['String']>;
};

export type LaporanZero = {
  __typename?: 'LaporanZero';
  no_rm?: Maybe<Scalars['String']>;
  diagnosa?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  icdx?: Maybe<Scalars['String']>;
  alergi?: Maybe<Scalars['String']>;
  njm?: Maybe<Scalars['String']>;
  forms?: Maybe<Scalars['String']>;
  tanggal_masuk?: Maybe<Scalars['String']>;
  tanggal_keluar?: Maybe<Scalars['String']>;
};

export type MasterDataMutation = {
  __typename?: 'MasterDataMutation';
  /** tambah dokter baru */
  dokterAdd?: Maybe<Dokter>;
  /** edit dokter */
  dokterEdit?: Maybe<Dokter>;
};

export type MasterDataMutationDokterAddArgs = {
  data: DokterData;
};

export type MasterDataMutationDokterEditArgs = {
  id: Scalars['Int'];
  data: DokterData;
};

export type MasterDataQuery = {
  __typename?: 'MasterDataQuery';
  /** daftar dokter */
  dokterList?: Maybe<DokterList>;
  /** cari dokter */
  dokterSearch?: Maybe<Array<Maybe<Dokter>>>;
  /** cari icd x */
  icdxSearch?: Maybe<Array<Maybe<Icd10>>>;
  /** cari tindakan */
  tindakanSearch?: Maybe<Array<Maybe<Tindakan>>>;
  /** informasi dokter berdasarkan id dokter */
  dokterById?: Maybe<Dokter>;
};

export type MasterDataQueryDokterListArgs = {
  keyword?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type MasterDataQueryDokterSearchArgs = {
  keyword?: Maybe<Scalars['String']>;
};

export type MasterDataQueryIcdxSearchArgs = {
  keyword?: Maybe<Scalars['String']>;
};

export type MasterDataQueryTindakanSearchArgs = {
  keyword?: Maybe<Scalars['String']>;
};

export type MasterDataQueryDokterByIdArgs = {
  id: Scalars['Int'];
};

/** gunakan API ini untuk melakukan perubahan `mutasi` data farmasi */
export type Mutation = {
  __typename?: 'Mutation';
  /** mutasi informasi Master Data */
  masterData?: Maybe<MasterDataMutation>;
  /** mutasi informasi Pasien */
  pasien?: Maybe<PasienMutation>;
  /** mutasi informasi Kunjungan */
  kunjungan?: Maybe<KunjunganMutation>;
  /** mutasi informasi medical record */
  rekamMedis?: Maybe<RekamMedisMutation>;
  /** mutasi informasi penunjang */
  penunjang?: Maybe<PenunjangMutation>;
};

export type Pasien = {
  __typename?: 'Pasien';
  /** id pasien */
  id?: Maybe<Scalars['Int']>;
  /** nama pasien */
  nama?: Maybe<Scalars['String']>;
  /** nomor rekam medis */
  noRm?: Maybe<Scalars['String']>;
  /** tanggal lahir */
  tanggalLahir?: Maybe<Scalars['Date']>;
  /** tempat lahir */
  tempatLahir?: Maybe<Scalars['String']>;
  /** jenis kelamin */
  jenisKelamin?: Maybe<JenisKelamin>;
  /** alamat tempat tinggal */
  alamat?: Maybe<Scalars['String']>;
  /** kabupaten atau kota tempat tinggal */
  kabupatenKota?: Maybe<Scalars['String']>;
  /** kecamatan tempat tinggal */
  kecamatan?: Maybe<Scalars['String']>;
  /** kelurahan tempat tinggal */
  kelurahan?: Maybe<Scalars['String']>;
  /** nama keluarga terdekat dengan pasien */
  namaKeluargaDekat?: Maybe<Scalars['String']>;
  /** no hp aktif pasien */
  noHp?: Maybe<Scalars['String']>;
  /** nik */
  nik?: Maybe<Scalars['String']>;
  /** no kartu */
  noKartu?: Maybe<Scalars['String']>;
};

export type PasienData = {
  /** nama pasien */
  nama?: Maybe<Scalars['String']>;
  /** nomor rekam medis */
  noRm?: Maybe<Scalars['String']>;
  /** tanggal lahir */
  tanggalLahir?: Maybe<Scalars['Date']>;
  /** tempat lahir */
  tempatLahir?: Maybe<Scalars['String']>;
  /** jenis kelamin */
  jenisKelamin?: Maybe<JenisKelamin>;
  /** alamat tempat tinggal */
  alamat?: Maybe<Scalars['String']>;
  /** kabupaten atau kota tempat tinggal */
  kabupatenKota?: Maybe<Scalars['String']>;
  /** kecamatan tempat tinggal */
  kecamatan?: Maybe<Scalars['String']>;
  /** kelurahan tempat tinggal */
  kelurahan?: Maybe<Scalars['String']>;
  /** nama keluarga terdekat dengan pasien */
  namaKeluargaDekat?: Maybe<Scalars['String']>;
  /** no hp aktif pasien */
  noHp?: Maybe<Scalars['String']>;
  /** riwayat masalah pasien */
  masalah?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** riwayat alergi pasien */
  alergi?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** riwayat pengobatan pasien */
  pengobatan?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** nik */
  nik?: Maybe<Scalars['String']>;
  /** no kartu */
  noKartu?: Maybe<Scalars['String']>;
};

export type PasienList = {
  __typename?: 'PasienList';
  /** daftar pasien */
  list?: Maybe<Array<Maybe<Pasien>>>;
  /** total pasien yg ada */
  count?: Maybe<Scalars['Int']>;
};

export type PasienMutation = {
  __typename?: 'PasienMutation';
  /** tambah pasien baru */
  add?: Maybe<Pasien>;
  /** edit data pasien */
  edit?: Maybe<Pasien>;
  /** tambah riwayat masalah pasien */
  tambahMasalah?: Maybe<RiwayatMasalah>;
  /** tambah riwayat alergi pasien */
  tambahAlergi?: Maybe<RiwayatAlergi>;
  /** tambah riwayat pengobatan pasien */
  tambahPengobatan?: Maybe<RiwayatPengobatan>;
  /** pindahkan masalah aktif ke masalah terdahulu */
  masalahSelesai?: Maybe<RiwayatMasalah>;
  /** pindahkan masalah terdahulu ke masalah aktif */
  masalahAktif?: Maybe<RiwayatMasalah>;
  /** pindahkan alergi aktif ke alergi terdahulu */
  alergiSelesai?: Maybe<RiwayatAlergi>;
  /** pindahkan alergi terdahulu ke alergi aktif */
  alergiAktif?: Maybe<RiwayatAlergi>;
  /** pindahkan pengobatan aktif ke pengobatan terdahulu */
  pengobatanSelesai?: Maybe<RiwayatPengobatan>;
  /** pindahkan pengobatan terdahulu ke pengobatan aktif */
  pengobatanAktif?: Maybe<RiwayatPengobatan>;
};

export type PasienMutationAddArgs = {
  data: PasienData;
};

export type PasienMutationEditArgs = {
  id: Scalars['Int'];
  data: PasienData;
};

export type PasienMutationTambahMasalahArgs = {
  pasienId: Scalars['Int'];
  icdCode: Scalars['String'];
  aktif: Scalars['Boolean'];
};

export type PasienMutationTambahAlergiArgs = {
  pasienId: Scalars['Int'];
  alergi: Scalars['String'];
  aktif: Scalars['Boolean'];
};

export type PasienMutationTambahPengobatanArgs = {
  pasienId: Scalars['Int'];
  pengobatan: Scalars['String'];
  aktif: Scalars['Boolean'];
};

export type PasienMutationMasalahSelesaiArgs = {
  pasienId: Scalars['Int'];
  icdCodes: Array<Scalars['String']>;
};

export type PasienMutationMasalahAktifArgs = {
  pasienId: Scalars['Int'];
  icdCodes: Array<Scalars['String']>;
};

export type PasienMutationAlergiSelesaiArgs = {
  pasienId: Scalars['Int'];
  alergis: Array<Scalars['String']>;
};

export type PasienMutationAlergiAktifArgs = {
  pasienId: Scalars['Int'];
  alergis: Array<Scalars['String']>;
};

export type PasienMutationPengobatanSelesaiArgs = {
  pasienId: Scalars['Int'];
  obats: Array<Scalars['String']>;
};

export type PasienMutationPengobatanAktifArgs = {
  pasienId: Scalars['Int'];
  obats: Array<Scalars['String']>;
};

export type PasienQuery = {
  __typename?: 'PasienQuery';
  /** daftar pasien */
  list?: Maybe<PasienList>;
  /** cari pasien */
  search?: Maybe<Array<Maybe<KunjunganPasien>>>;
  /** informasi pasien berdasarkan id pasien */
  byId?: Maybe<Pasien>;
  /** daftar riwayat masalah pasien */
  riwayatMasalah?: Maybe<RiwayatMasalah>;
  /** daftar riwayat alergi pasien */
  riwayatAlergi?: Maybe<RiwayatAlergi>;
  /** daftar riwayat pengobatan pasien */
  riwayatPengobatan?: Maybe<RiwayatPengobatan>;
};

export type PasienQueryListArgs = {
  keyword?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PasienQuerySearchArgs = {
  keyword?: Maybe<Scalars['String']>;
};

export type PasienQueryByIdArgs = {
  id: Scalars['Int'];
};

export type PasienQueryRiwayatMasalahArgs = {
  pasienId: Scalars['Int'];
};

export type PasienQueryRiwayatAlergiArgs = {
  pasienId: Scalars['Int'];
};

export type PasienQueryRiwayatPengobatanArgs = {
  pasienId: Scalars['Int'];
};

export type Penunjang = {
  __typename?: 'Penunjang';
  /** id penunjang */
  id?: Maybe<Scalars['Int']>;
  /** tipe penunjang */
  type?: Maybe<PenunjangType>;
  /** kunjungan */
  kunjungan?: Maybe<Kunjungan>;
  /** dokumen */
  dokumen?: Maybe<DokumenRekamMedis>;
  /** status penunjang */
  status?: Maybe<PenunjangStatus>;
  /** dokter */
  dpjp?: Maybe<Dokter>;
  /** unit */
  unit?: Maybe<Unit>;
  /** catatan */
  notes?: Maybe<Scalars['String']>;
  /** waktu request */
  waktuRequest?: Maybe<Scalars['Date']>;
};

export type PenunjangData = {
  /** tipe penunjang */
  type?: Maybe<PenunjangType>;
  /** id kunjungan */
  idKunjungan?: Maybe<Scalars['Int']>;
  /** dokumen */
  idDokumen?: Maybe<Scalars['String']>;
  /** status penunjang */
  status?: Maybe<PenunjangStatus>;
  /** id dokter */
  dpjpId?: Maybe<Scalars['Int']>;
  /** unit */
  unit?: Maybe<Unit>;
};

export type PenunjangList = {
  __typename?: 'PenunjangList';
  /** daftar penunjang */
  list?: Maybe<Array<Maybe<Penunjang>>>;
  /** total penunjang yg ada */
  count?: Maybe<Scalars['Int']>;
};

export type PenunjangMutation = {
  __typename?: 'PenunjangMutation';
  add?: Maybe<Penunjang>;
  batal?: Maybe<Penunjang>;
  selesai?: Maybe<Penunjang>;
};

export type PenunjangMutationAddArgs = {
  data: PenunjangData;
};

export type PenunjangMutationBatalArgs = {
  id: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
};

export type PenunjangMutationSelesaiArgs = {
  id: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
};

export type PenunjangQuery = {
  __typename?: 'PenunjangQuery';
  list?: Maybe<PenunjangList>;
  /** detail penunjang berdasarkan id */
  byId?: Maybe<Penunjang>;
};

export type PenunjangQueryListArgs = {
  sortBy?: Maybe<PenunjangSortBy>;
  sortDesc?: Maybe<Scalars['Boolean']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<PenunjangStatus>>>;
  type?: Maybe<PenunjangType>;
  dpjpId?: Maybe<Scalars['Int']>;
  unit?: Maybe<Unit>;
};

export type PenunjangQueryByIdArgs = {
  id: Scalars['Int'];
};

export enum PenunjangSortBy {
  CreatedAt = 'createdAt',
  NamaPasien = 'namaPasien',
  NoRm = 'noRm'
}

export enum PenunjangStatus {
  Request = 'Request',
  Batal = 'Batal',
  Setuju = 'Setuju'
}

export enum PenunjangType {
  Lab = 'Lab',
  Radiologi = 'Radiologi'
}

/**
 * daftar `query` informasi yang dabat dibaca
 * `query` hanya dilakukan untuk operasi-operasi bersifat `read-only`
 */
export type Query = {
  __typename?: 'Query';
  /** query informasi Master Data */
  masterData?: Maybe<MasterDataQuery>;
  /** query informasi Pasien */
  pasien?: Maybe<PasienQuery>;
  /** query informasi Kunjungan */
  kunjungan?: Maybe<KunjunganQuery>;
  /** query informasi medical record */
  rekamMedis?: Maybe<RekamMedisQuery>;
  /** query informasi support */
  support?: Maybe<SupportQuery>;
  /** query informasi penunjang */
  penunjang?: Maybe<PenunjangQuery>;
  /** query informasi laporan */
  laporan?: Maybe<LaporanQuery>;
};

export type RegisterHcu = {
  __typename?: 'RegisterHcu';
  no_rm?: Maybe<Scalars['String']>;
  nama_pasien?: Maybe<Scalars['String']>;
  tanggal_umur?: Maybe<Scalars['String']>;
  dpjp?: Maybe<Scalars['String']>;
  jaminan?: Maybe<Scalars['String']>;
  diagnosis?: Maybe<Scalars['String']>;
  status_pulang?: Maybe<Scalars['String']>;
  rujukan?: Maybe<Scalars['String']>;
  jam_masuk?: Maybe<Scalars['String']>;
  jam_keluar?: Maybe<Scalars['String']>;
  infeksi?: Maybe<Scalars['String']>;
  cabak?: Maybe<Scalars['String']>;
  rawat_selanjutnya?: Maybe<Scalars['String']>;
};

export type RegisterIcu = {
  __typename?: 'RegisterIcu';
  no_rm?: Maybe<Scalars['String']>;
  nama_pasien?: Maybe<Scalars['String']>;
  tanggal_umur?: Maybe<Scalars['String']>;
  dpjp?: Maybe<Scalars['String']>;
  jaminan?: Maybe<Scalars['String']>;
  diagnosis?: Maybe<Scalars['String']>;
  ventilator?: Maybe<Scalars['String']>;
  status_pulang?: Maybe<Scalars['String']>;
  rujukan?: Maybe<Scalars['String']>;
  jam_masuk?: Maybe<Scalars['String']>;
  jam_keluar?: Maybe<Scalars['String']>;
  infeksi?: Maybe<Scalars['String']>;
  cabak?: Maybe<Scalars['String']>;
  rawat_selanjutnya?: Maybe<Scalars['String']>;
};

export type RegisterOk = {
  __typename?: 'RegisterOk';
  no_rm?: Maybe<Scalars['String']>;
  nama_pasien?: Maybe<Scalars['String']>;
  tanggal_umur?: Maybe<Scalars['String']>;
  dokter?: Maybe<Scalars['String']>;
  dokter_anastesi?: Maybe<Scalars['String']>;
  diagnosis?: Maybe<Scalars['String']>;
  tindakan?: Maybe<Scalars['String']>;
  klasifikasi?: Maybe<Scalars['String']>;
  jam_operasi?: Maybe<Scalars['String']>;
  jam_mulai?: Maybe<Scalars['String']>;
  waktu_tunggu?: Maybe<Scalars['String']>;
  alasan_penundaan?: Maybe<Scalars['String']>;
  asisten?: Maybe<Scalars['String']>;
  instrumen?: Maybe<Scalars['String']>;
  sirkuler?: Maybe<Scalars['String']>;
  recovery_room?: Maybe<Scalars['String']>;
};

export type RegisterPerina = {
  __typename?: 'RegisterPerina';
  no_rm?: Maybe<Scalars['String']>;
  nama_pasien?: Maybe<Scalars['String']>;
  tanggal_umur?: Maybe<Scalars['String']>;
  klasifikasi?: Maybe<Scalars['String']>;
  dpjp?: Maybe<Scalars['String']>;
  berat_badan?: Maybe<Scalars['String']>;
  asal_bayi?: Maybe<Scalars['String']>;
  diagnosis?: Maybe<Scalars['String']>;
  status_pulang?: Maybe<Scalars['String']>;
  no_hp?: Maybe<Scalars['String']>;
  tanggal_pulang?: Maybe<Scalars['String']>;
  tanggal_kontrol?: Maybe<Scalars['String']>;
  jam_masuk_ri?: Maybe<Scalars['String']>;
  jam_asses_perawat?: Maybe<Scalars['String']>;
  jam_asses_dokter?: Maybe<Scalars['String']>;
  infeksi?: Maybe<Scalars['String']>;
  cabak?: Maybe<Scalars['String']>;
  dokter_jaga?: Maybe<Scalars['String']>;
  perawat?: Maybe<Scalars['String']>;
};

export type RegisterPermintaanLab = {
  __typename?: 'RegisterPermintaanLab';
  tanggal?: Maybe<Scalars['String']>;
  no_rm?: Maybe<Scalars['String']>;
  nama_pasien?: Maybe<Scalars['String']>;
  tanggal_lahir?: Maybe<Scalars['String']>;
  jenis_kelamin?: Maybe<Scalars['String']>;
  dokter?: Maybe<Scalars['String']>;
  diagnosis?: Maybe<Scalars['String']>;
  keterangan_klinis?: Maybe<Scalars['String']>;
  kategori?: Maybe<Scalars['String']>;
  pemeriksaan?: Maybe<Scalars['String']>;
  jaminan?: Maybe<Scalars['String']>;
  perusahaan?: Maybe<Scalars['String']>;
  kelas_no_kamar?: Maybe<Scalars['String']>;
  cara_bayar?: Maybe<Scalars['String']>;
};

export type RegisterPermintaanRad = {
  __typename?: 'RegisterPermintaanRad';
  tanggal?: Maybe<Scalars['String']>;
  no_rm?: Maybe<Scalars['String']>;
  nama_pasien?: Maybe<Scalars['String']>;
  tanggal_lahir?: Maybe<Scalars['String']>;
  jenis_kelamin?: Maybe<Scalars['String']>;
  dokter?: Maybe<Scalars['String']>;
  diagnosis?: Maybe<Scalars['String']>;
  keterangan_klinis?: Maybe<Scalars['String']>;
  kategori?: Maybe<Scalars['String']>;
  pemeriksaan?: Maybe<Scalars['String']>;
  jaminan?: Maybe<Scalars['String']>;
  perusahaan?: Maybe<Scalars['String']>;
  kelas_no_kamar?: Maybe<Scalars['String']>;
  cara_bayar?: Maybe<Scalars['String']>;
};

export type RegisterRawatInap = {
  __typename?: 'RegisterRawatInap';
  no_rm?: Maybe<Scalars['String']>;
  nama_pasien?: Maybe<Scalars['String']>;
  tanggal_umur?: Maybe<Scalars['String']>;
  dpjp?: Maybe<Scalars['String']>;
  diagnosis?: Maybe<Scalars['String']>;
  status_pulang?: Maybe<Scalars['String']>;
  tujuan_rujukan?: Maybe<Scalars['String']>;
  tanggal_pulang?: Maybe<Scalars['String']>;
  tanggal_kontrol?: Maybe<Scalars['String']>;
  no_hp?: Maybe<Scalars['String']>;
  jam_masuk?: Maybe<Scalars['String']>;
  jam_asses_perawat?: Maybe<Scalars['String']>;
  jam_asses_dokter?: Maybe<Scalars['String']>;
  infeksi?: Maybe<Scalars['String']>;
  cabak?: Maybe<Scalars['String']>;
};

export type RegisterUgd = {
  __typename?: 'RegisterUgd';
  no_rm?: Maybe<Scalars['String']>;
  nama_pasien?: Maybe<Scalars['String']>;
  tanggal_umur?: Maybe<Scalars['String']>;
  tanggal_masuk?: Maybe<Scalars['String']>;
  jam_masuk?: Maybe<Scalars['String']>;
  tanggal_diperiksa?: Maybe<Scalars['String']>;
  jam_diperiksa?: Maybe<Scalars['String']>;
  tindaklanjut?: Maybe<Scalars['String']>;
  diagnosis?: Maybe<Scalars['String']>;
  dpjp?: Maybe<Scalars['String']>;
  drugd?: Maybe<Scalars['String']>;
  no_kamar?: Maybe<Scalars['String']>;
  perawat?: Maybe<Scalars['String']>;
};

export type RegisterVk = {
  __typename?: 'RegisterVk';
  tanggal_masuk?: Maybe<Scalars['String']>;
  tanggal_keluar?: Maybe<Scalars['String']>;
  no_rm?: Maybe<Scalars['String']>;
  nama_pasien?: Maybe<Scalars['String']>;
  tanggal_umur?: Maybe<Scalars['String']>;
  operator?: Maybe<Scalars['String']>;
  anastesi?: Maybe<Scalars['String']>;
  tindakan?: Maybe<Scalars['String']>;
  klasifikasi?: Maybe<Scalars['String']>;
  kelas?: Maybe<Scalars['String']>;
  diagnosis?: Maybe<Scalars['String']>;
  jam_mulai?: Maybe<Scalars['String']>;
  jam_selesai?: Maybe<Scalars['String']>;
  status_pulang?: Maybe<Scalars['String']>;
  rujukan?: Maybe<Scalars['String']>;
  alamat?: Maybe<Scalars['String']>;
  no_telp?: Maybe<Scalars['String']>;
  pendamping?: Maybe<Scalars['String']>;
  asistensi_tindakan?: Maybe<Scalars['String']>;
};

export type RekamMedisMutation = {
  __typename?: 'RekamMedisMutation';
  /** pendaftaran dokumen rekam medis baru */
  create?: Maybe<DokumenRekamMedis>;
  /** pendaftaran dokumen rekam medis secara batch (sekaligus) */
  bulkCreate?: Maybe<Array<Maybe<DokumenRekamMedis>>>;
  /** ubah dokumen rekam medis */
  update?: Maybe<DokumenRekamMedis>;
  /** hapus dokumen reka medis */
  remove?: Maybe<DokumenRekamMedis>;
};

export type RekamMedisMutationCreateArgs = {
  data: DokumenRekamMedisData;
};

export type RekamMedisMutationBulkCreateArgs = {
  data: Array<DokumenRekamMedisData>;
};

export type RekamMedisMutationUpdateArgs = {
  id: Scalars['String'];
  data: DokumenRekamMedisData;
};

export type RekamMedisMutationRemoveArgs = {
  id: Scalars['String'];
};

export type RekamMedisQuery = {
  __typename?: 'RekamMedisQuery';
  /** daftar dokument rekam medis */
  list?: Maybe<DokumenRekamMedisList>;
  /** detail dokumen rekam medis berdasarkan id */
  byId?: Maybe<DokumenRekamMedis>;
};

export type RekamMedisQueryListArgs = {
  filter?: Maybe<DokumenRekamMedisFilter>;
  sortBy?: Maybe<DokumenSortBy>;
  sortDesc?: Maybe<Scalars['Boolean']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
};

export type RekamMedisQueryByIdArgs = {
  id: Scalars['String'];
};

export type RiwayatAlergi = {
  __typename?: 'RiwayatAlergi';
  alergiAktif?: Maybe<Array<Maybe<Scalars['String']>>>;
  alergiTerdahulu?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RiwayatMasalah = {
  __typename?: 'RiwayatMasalah';
  masalahAktif?: Maybe<Array<Maybe<Icd10>>>;
  masalahTerdahulu?: Maybe<Array<Maybe<Icd10>>>;
};

export type RiwayatPengobatan = {
  __typename?: 'RiwayatPengobatan';
  pengobatanAktif?: Maybe<Array<Maybe<Scalars['String']>>>;
  pengobatanTerdahulu?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** sort direction */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum Status {
  Baru = 'Baru',
  Lama = 'Lama'
}

export enum StatusFinalisasi {
  Pulang = 'Pulang',
  Aps = 'APS',
  Meninggal = 'Meninggal',
  RujukEksternal = 'RujukEksternal',
  RujukInternal = 'RujukInternal'
}

export enum StatusKunjungan {
  Terdaftar = 'Terdaftar',
  Selesai = 'Selesai',
  Finalisasi = 'Finalisasi',
  SelesaiFinalisasi = 'SelesaiFinalisasi',
  Batal = 'Batal'
}

export enum StatusPemeriksaan {
  TungguResume = 'TungguResume',
  Titipan = 'Titipan',
  OperasiOk = 'OperasiOK',
  TungguHasilPenunjang = 'TungguHasilPenunjang',
  TriaseLevel1 = 'TriaseLevel1',
  TriaseLevel2 = 'TriaseLevel2',
  TriaseLevel3 = 'TriaseLevel3',
  TriaseLevel4 = 'TriaseLevel4',
  TriaseLevel5 = 'TriaseLevel5'
}

export type SupportQuery = {
  __typename?: 'SupportQuery';
  /** get aws temporary token */
  getOpenIdTokenForDeveloperIdentity?: Maybe<CognitoIdentityCredential>;
};

export type Tindakan = {
  __typename?: 'Tindakan';
  /** kode tindakan */
  kode?: Maybe<Scalars['String']>;
  /** deskripsi tindakan */
  deskripsi?: Maybe<Scalars['String']>;
};

export enum Unit {
  MultiUnit = 'MultiUnit',
  Ugd = 'Ugd',
  Poli = 'Poli',
  RawatInap = 'RawatInap',
  Ok = 'Ok',
  Vk = 'Vk',
  Mcu = 'Mcu',
  Icu = 'Icu',
  Hcu = 'Hcu',
  Perina = 'Perina'
}

/** riwayat edit dokumen rekam medis */
export type UpdateHistory = {
  __typename?: 'UpdateHistory';
  editorId?: Maybe<Scalars['Int']>;
  editorName?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['Time']>;
  /** perubahan yang dilakukan */
  diff?: Maybe<Scalars['String']>;
};

export type UtilisasiPoli = {
  __typename?: 'UtilisasiPoli';
  poli?: Maybe<Scalars['String']>;
  dokter?: Maybe<Scalars['String']>;
  spesialis?: Maybe<Scalars['String']>;
  no_ruang?: Maybe<Scalars['String']>;
  hari?: Maybe<Scalars['String']>;
  tanggal?: Maybe<Scalars['String']>;
  jadwal?: Maybe<Scalars['String']>;
  durasi?: Maybe<Scalars['String']>;
  jam_datang_dokter?: Maybe<Scalars['String']>;
  jam_pulang_dokter?: Maybe<Scalars['String']>;
  durasi_aktual?: Maybe<Scalars['String']>;
  keterlambatan?: Maybe<Scalars['String']>;
  total_selesai?: Maybe<Scalars['String']>;
  total_batal?: Maybe<Scalars['String']>;
};

export type AddDokterMutationVariables = Exact<{
  data: DokterData;
}>;

export type AddDokterMutation = { __typename?: 'Mutation' } & {
  masterData?: Maybe<
    { __typename?: 'MasterDataMutation' } & {
      dokterAdd?: Maybe<
        { __typename?: 'Dokter' } & Pick<Dokter, 'id' | 'nama'>
      >;
    }
  >;
};

export type DokterSearchQueryVariables = Exact<{
  keyword?: Maybe<Scalars['String']>;
}>;

export type DokterSearchQuery = { __typename?: 'Query' } & {
  masterData?: Maybe<
    { __typename?: 'MasterDataQuery' } & {
      dokterSearch?: Maybe<
        Array<Maybe<{ __typename?: 'Dokter' } & Pick<Dokter, 'id' | 'nama'>>>
      >;
    }
  >;
};

export type GetAwsCredQueryVariables = Exact<{ [key: string]: never }>;

export type GetAwsCredQuery = { __typename?: 'Query' } & {
  support?: Maybe<
    { __typename?: 'SupportQuery' } & {
      getOpenIdTokenForDeveloperIdentity?: Maybe<
        { __typename?: 'CognitoIdentityCredential' } & Pick<
          CognitoIdentityCredential,
          'identityId' | 'token' | 'logins'
        >
      >;
    }
  >;
};

export type SearchDokterQueryVariables = Exact<{
  keyword?: Maybe<Scalars['String']>;
}>;

export type SearchDokterQuery = { __typename?: 'Query' } & {
  masterData?: Maybe<
    { __typename?: 'MasterDataQuery' } & {
      dokterSearch?: Maybe<
        Array<Maybe<{ __typename?: 'Dokter' } & Pick<Dokter, 'id' | 'nama'>>>
      >;
    }
  >;
};

export type SearchPasienQueryVariables = Exact<{
  keyword?: Maybe<Scalars['String']>;
}>;

export type SearchPasienQuery = { __typename?: 'Query' } & {
  pasien?: Maybe<
    { __typename?: 'PasienQuery' } & {
      search?: Maybe<
        Array<
          Maybe<
            { __typename?: 'KunjunganPasien' } & {
              pasien?: Maybe<
                { __typename?: 'Pasien' } & Pick<
                  Pasien,
                  | 'id'
                  | 'nama'
                  | 'noRm'
                  | 'tanggalLahir'
                  | 'tempatLahir'
                  | 'jenisKelamin'
                  | 'alamat'
                  | 'kabupatenKota'
                  | 'kecamatan'
                  | 'kelurahan'
                  | 'namaKeluargaDekat'
                  | 'noHp'
                >
              >;
              kunjungan?: Maybe<
                { __typename?: 'Kunjungan' } & Pick<
                  Kunjungan,
                  'status' | 'unit'
                >
              >;
            }
          >
        >
      >;
    }
  >;
};

export type LaporanJumlahPasienQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type LaporanJumlahPasienQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      jumlahPasien?: Maybe<
        Array<
          Maybe<
            { __typename?: 'JumlahPasien' } & Pick<
              JumlahPasien,
              | 'poli'
              | 'dokter'
              | 'spesialis'
              | 'tanggal'
              | 'pasienselesai'
              | 'pasienbatal'
              | 'pasienlama'
              | 'pasienbaru'
              | 'klasifikasi_bpjs'
              | 'klasifikasi_pribadi'
              | 'klasifikasi_rekanan'
            >
          >
        >
      >;
    }
  >;
};

export type LaporanBatalQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type LaporanBatalQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      laporanBatal?: Maybe<
        Array<
          Maybe<
            { __typename?: 'LaporanBatal' } & Pick<
              LaporanBatal,
              | 'tanggal'
              | 'nama_pasien'
              | 'no_rm'
              | 'unit'
              | 'dokter'
              | 'umur'
              | 'jam_masuk'
              | 'jam_pembatalan'
              | 'alasan'
              | 'perawat'
            >
          >
        >
      >;
    }
  >;
};

export type LaporanMultiUnitQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type LaporanMultiUnitQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      laporanMultiUnit?: Maybe<
        Array<
          Maybe<
            { __typename?: 'LaporanMultiUnit' } & Pick<
              LaporanMultiUnit,
              | 'nama_pasien'
              | 'umur'
              | 'umurBulan'
              | 'no_kirs'
              | 'kelamin'
              | 'alamat'
              | 'kota'
              | 'suami'
              | 'klasifikasi'
              | 'tanggal_masuk'
              | 'tanggal_keluar'
              | 'lama_rawat'
              | 'rawat_kelas'
              | 'rujukan_perujuk'
              | 'rujukan_dirujuk'
              | 'kasus'
              | 'diagnosa'
              | 'icdx'
              | 'tindakan'
              | 'berat_bayi'
              | 'panjang_bayi'
              | 'lingkar_kepala_bayi'
              | 'lahir_mati'
              | 'klasifikasi_tindakan'
              | 'dpjp'
              | 'spesialis'
              | 'meninggal'
            >
          >
        >
      >;
    }
  >;
};

export type LaporanPoliQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type LaporanPoliQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      laporanPoli?: Maybe<
        Array<
          Maybe<
            { __typename?: 'LaporanPoli' } & Pick<
              LaporanPoli,
              | 'tanggal'
              | 'nama_pasien'
              | 'no_kirs'
              | 'dokter'
              | 'spesialis'
              | 'rujukan_perujuk'
              | 'rujukan_dirujuk'
              | 'umur'
              | 'kelamin'
              | 'klasifikasi'
              | 'kunjungan'
              | 'kasus'
              | 'diagnosa'
              | 'icd'
            >
          >
        >
      >;
    }
  >;
};

export type LaporanUgdQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type LaporanUgdQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      laporanUgd?: Maybe<
        Array<
          Maybe<
            { __typename?: 'LaporanUgd' } & Pick<
              LaporanUgd,
              | 'tanggal'
              | 'jam_masuk'
              | 'nama_pasien'
              | 'no_kirs'
              | 'dokter'
              | 'spesialis'
              | 'rujukan_perujuk'
              | 'rujukan_dirujuk'
              | 'umur'
              | 'umurBulan'
              | 'kelamin'
              | 'klasifikasi'
              | 'status'
              | 'kecelakaan'
              | 'rawat_kelas'
              | 'kunjungan'
              | 'kasus'
              | 'diagnosa'
              | 'icd'
              | 'pelayanan'
            >
          >
        >
      >;
    }
  >;
};

export type LaporanPermintaanLabQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type LaporanPermintaanLabQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      laporanPermintaanLab?: Maybe<
        Array<
          Maybe<
            { __typename?: 'LaporanPermintaanLab' } & Pick<
              LaporanPermintaanLab,
              | 'nama_pemeriksaan'
              | 'kategori'
              | 'jaminan_bpjs'
              | 'jaminan_pribadi'
              | 'jaminan_asuransi'
              | 'jaminan_lain'
              | 'total'
            >
          >
        >
      >;
    }
  >;
};

export type LaporanPermintaanRadQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type LaporanPermintaanRadQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      laporanPermintaanRad?: Maybe<
        Array<
          Maybe<
            { __typename?: 'LaporanPermintaanRad' } & Pick<
              LaporanPermintaanRad,
              | 'nama_pemeriksaan'
              | 'kategori'
              | 'jaminan_bpjs'
              | 'jaminan_pribadi'
              | 'jaminan_asuransi'
              | 'jaminan_lain'
              | 'total'
            >
          >
        >
      >;
    }
  >;
};

export type LaporanUtilisasiPoliQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type LaporanUtilisasiPoliQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      utilisasiPoli?: Maybe<
        Array<
          Maybe<
            { __typename?: 'UtilisasiPoli' } & Pick<
              UtilisasiPoli,
              | 'poli'
              | 'dokter'
              | 'spesialis'
              | 'no_ruang'
              | 'hari'
              | 'tanggal'
              | 'jadwal'
              | 'durasi'
              | 'jam_datang_dokter'
              | 'jam_pulang_dokter'
              | 'durasi_aktual'
              | 'keterlambatan'
              | 'total_selesai'
              | 'total_batal'
            >
          >
        >
      >;
    }
  >;
};

export type LaporanZeroQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type LaporanZeroQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      laporanZero?: Maybe<
        Array<
          Maybe<
            { __typename?: 'LaporanZero' } & Pick<
              LaporanZero,
              | 'no_rm'
              | 'diagnosa'
              | 'unit'
              | 'icdx'
              | 'alergi'
              | 'njm'
              | 'forms'
              | 'tanggal_masuk'
              | 'tanggal_keluar'
            >
          >
        >
      >;
    }
  >;
};

export type RegisterHcuQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type RegisterHcuQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      registerHcu?: Maybe<
        Array<
          Maybe<
            { __typename?: 'RegisterHcu' } & Pick<
              RegisterHcu,
              | 'no_rm'
              | 'nama_pasien'
              | 'tanggal_umur'
              | 'dpjp'
              | 'jaminan'
              | 'diagnosis'
              | 'status_pulang'
              | 'rujukan'
              | 'jam_masuk'
              | 'jam_keluar'
              | 'infeksi'
              | 'cabak'
              | 'rawat_selanjutnya'
            >
          >
        >
      >;
    }
  >;
};

export type RegisterIcuQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type RegisterIcuQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      registerIcu?: Maybe<
        Array<
          Maybe<
            { __typename?: 'RegisterIcu' } & Pick<
              RegisterIcu,
              | 'no_rm'
              | 'nama_pasien'
              | 'tanggal_umur'
              | 'dpjp'
              | 'jaminan'
              | 'diagnosis'
              | 'ventilator'
              | 'status_pulang'
              | 'rujukan'
              | 'jam_masuk'
              | 'jam_keluar'
              | 'infeksi'
              | 'cabak'
              | 'rawat_selanjutnya'
            >
          >
        >
      >;
    }
  >;
};

export type RegisterOkQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type RegisterOkQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      registerOk?: Maybe<
        Array<
          Maybe<
            { __typename?: 'RegisterOk' } & Pick<
              RegisterOk,
              | 'no_rm'
              | 'nama_pasien'
              | 'tanggal_umur'
              | 'dokter'
              | 'dokter_anastesi'
              | 'diagnosis'
              | 'tindakan'
              | 'klasifikasi'
              | 'jam_operasi'
              | 'jam_mulai'
              | 'waktu_tunggu'
              | 'alasan_penundaan'
              | 'asisten'
              | 'instrumen'
              | 'sirkuler'
              | 'recovery_room'
            >
          >
        >
      >;
    }
  >;
};

export type RegisterPerinaQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type RegisterPerinaQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      registerPerina?: Maybe<
        Array<
          Maybe<
            { __typename?: 'RegisterPerina' } & Pick<
              RegisterPerina,
              | 'no_rm'
              | 'nama_pasien'
              | 'tanggal_umur'
              | 'klasifikasi'
              | 'dpjp'
              | 'diagnosis'
              | 'berat_badan'
              | 'asal_bayi'
              | 'status_pulang'
              | 'no_hp'
              | 'tanggal_pulang'
              | 'tanggal_kontrol'
              | 'jam_masuk_ri'
              | 'jam_asses_perawat'
              | 'jam_asses_dokter'
              | 'infeksi'
              | 'cabak'
              | 'dokter_jaga'
              | 'perawat'
            >
          >
        >
      >;
    }
  >;
};

export type RegisterPermintaanLabQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type RegisterPermintaanLabQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      registerPermintaanLab?: Maybe<
        Array<
          Maybe<
            { __typename?: 'RegisterPermintaanLab' } & Pick<
              RegisterPermintaanLab,
              | 'tanggal'
              | 'no_rm'
              | 'nama_pasien'
              | 'tanggal_lahir'
              | 'jenis_kelamin'
              | 'dokter'
              | 'diagnosis'
              | 'keterangan_klinis'
              | 'kategori'
              | 'pemeriksaan'
              | 'jaminan'
              | 'perusahaan'
              | 'kelas_no_kamar'
              | 'cara_bayar'
            >
          >
        >
      >;
    }
  >;
};

export type RegisterPermintaanRadQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type RegisterPermintaanRadQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      registerPermintaanRad?: Maybe<
        Array<
          Maybe<
            { __typename?: 'RegisterPermintaanRad' } & Pick<
              RegisterPermintaanRad,
              | 'tanggal'
              | 'no_rm'
              | 'nama_pasien'
              | 'tanggal_lahir'
              | 'jenis_kelamin'
              | 'dokter'
              | 'diagnosis'
              | 'keterangan_klinis'
              | 'kategori'
              | 'pemeriksaan'
              | 'jaminan'
              | 'perusahaan'
              | 'kelas_no_kamar'
              | 'cara_bayar'
            >
          >
        >
      >;
    }
  >;
};

export type RegisterRawatInapQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type RegisterRawatInapQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      registerRawatInap?: Maybe<
        Array<
          Maybe<
            { __typename?: 'RegisterRawatInap' } & Pick<
              RegisterRawatInap,
              | 'no_rm'
              | 'nama_pasien'
              | 'tanggal_umur'
              | 'dpjp'
              | 'diagnosis'
              | 'status_pulang'
              | 'tujuan_rujukan'
              | 'tanggal_pulang'
              | 'tanggal_kontrol'
              | 'no_hp'
              | 'jam_masuk'
              | 'jam_asses_perawat'
              | 'jam_asses_dokter'
              | 'infeksi'
              | 'cabak'
            >
          >
        >
      >;
    }
  >;
};

export type RegisterUgdQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type RegisterUgdQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      registerUgd?: Maybe<
        Array<
          Maybe<
            { __typename?: 'RegisterUgd' } & Pick<
              RegisterUgd,
              | 'no_rm'
              | 'nama_pasien'
              | 'tanggal_umur'
              | 'tanggal_masuk'
              | 'jam_masuk'
              | 'tanggal_diperiksa'
              | 'jam_diperiksa'
              | 'tindaklanjut'
              | 'diagnosis'
              | 'dpjp'
              | 'drugd'
              | 'no_kamar'
              | 'perawat'
            >
          >
        >
      >;
    }
  >;
};

export type RegisterVkQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type RegisterVkQuery = { __typename?: 'Query' } & {
  laporan?: Maybe<
    { __typename?: 'LaporanQuery' } & {
      registerVk?: Maybe<
        Array<
          Maybe<
            { __typename?: 'RegisterVk' } & Pick<
              RegisterVk,
              | 'tanggal_masuk'
              | 'tanggal_keluar'
              | 'no_rm'
              | 'nama_pasien'
              | 'tanggal_umur'
              | 'operator'
              | 'anastesi'
              | 'tindakan'
              | 'klasifikasi'
              | 'kelas'
              | 'diagnosis'
              | 'jam_mulai'
              | 'jam_selesai'
              | 'status_pulang'
              | 'rujukan'
              | 'alamat'
              | 'no_telp'
              | 'pendamping'
              | 'asistensi_tindakan'
            >
          >
        >
      >;
    }
  >;
};
