import { SSOUserProfile } from '@mary-cileungsi/sso-client/types';
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import InitialFilter from '~/filters/initial';
import authenticated from '~/middleware/authenticated';

export interface Menu {
  id: string;
  icon?: string;
  title: string;
  active?: boolean;
  to?: string;
  items?: Menu[];
}

/**
 * Default layout page
 */
@Component({
  middleware: [authenticated],
  filters: {
    initial: InitialFilter
  }
})
export default class AppLayout extends Vue {
  @State((state) => state.dashboard.title) title: any;
  @State((state) => state.dashboard.breadcrumbs) breadcrumbs: any;

  collapsed = false;
  isUpdate = false;
  buildNumber = process.env.buildNumber;

  profile: SSOUserProfile = {
    name: '',
    email: '',
    id: null,
    photo: '',
    role: null,
    scopes: []
  };

  /**
   * listen app update
   */
  async mounted() {
    const workbox = await window['$workbox']; // eslint-disable-line
    if (workbox) {
      workbox.addEventListener('installed', (event) => {
        this.isUpdate = event?.isUpdate || false;
      });
    }
    await this.getProfile();
  }

  get copyright() {
    const year = new Date().getFullYear();
    return year > 2020 ? `2020-${year}` : '2020';
  }

  updateApp() {
    localStorage.clear();
    this.isUpdate = false;
    location.reload();
  }

  /**
   * exit to login page
   */
  logout() {
    this.$sso.logout();
  }

  async getProfile() {
    this.profile = await this.$sso.getProfile();
    this.$sentry.setUser({
      id: this.profile.id.toString(),
      email: this.profile.email,
      username: this.profile.name
    });
  }

  editProfile() {
    window.location.href =
      process.env.iamPortalUrl || 'https://rsmch.linimasa.co.id';
  }

  onCollapse(collapsed: boolean) {
    this.collapsed = collapsed;
  }
}
