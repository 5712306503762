import { v4 as uuid } from 'uuid';
import { getDiagnosis } from '~/lib/mock';

export interface PasienState {
  list: Pasien[];
}

export type Pasien = {
  id?: string;
  nama?: string;
  noRm?: string;
  tanggalLahir?: Date;
  jenisKelamin?: JenisKelamin;
  alamat?: string;
  kabupatenKota?: string;
  kecamatan?: string;
  kelurahan?: string;
  namaKeluargaDekat?: string;
  noHp?: string;
  activeProblems?: Problem[];
  inactiveProblems?: Problem[];
  activeAllergies?: Allergy[];
  inactiveAllergies?: Allergy[];
  activeMedications?: string[];
  inactiveMedications?: string[];
  cpptHistory?: CPPT[];
};

export interface Problem {
  id: string;
  code: string;
  name: string;
}

export interface Allergy {
  id: string;
  type: string;
  detail: string;
}

export interface CPPT {
  id: string;
  admissionDate: Date;
  dischargeDate: Date;
  dpjp: string;
}

export interface PasienData {
  nama?: string;
  noRm?: string;
  tanggalLahir?: Date;
  jenisKelamin?: JenisKelamin;
  alamat?: string;
  kabupatenKota?: string;
  kecamatan?: string;
  kelurahan?: string;
  namaKeluargaDekat?: string;
  noHp?: string;
  masalah?: string[];
  alergi?: string[];
  pengobatan?: string[];
}

export enum JenisKelamin {
  L = 'L',
  P = 'P'
}

export enum Status {
  Baru = 'Baru',
  Lama = 'Lama'
}

export function state(): PasienState {
  return {
    list: []
  };
}

export const mutations = {
  /**
   * Load patient data
   */
  loadPatients(state: PasienState, patients: Pasien[]): PasienState {
    state.list = patients;
    return state;
  },
  /**
   * add problem
   */
  addProblem(
    state: PasienState,
    data: {
      medicalRecordNumber: string;
      problem: { code: string; isResolved: boolean };
    }
  ) {
    const pasien = state.list.find((p) => p.noRm === data.medicalRecordNumber);

    const allDiagnosis = getDiagnosis();
    const diagnosis = allDiagnosis.find((d) => d.id === data.problem.code);

    if (data.problem.isResolved) {
      pasien.inactiveProblems.push({
        id: uuid(),
        code: diagnosis.code,
        name: diagnosis.name
      });
      return;
    }

    pasien.activeProblems.push({
      id: uuid(),
      code: diagnosis.code,
      name: diagnosis.name
    });
  },
  /**
   * remove problem
   */
  removeProblem(
    state: PasienState,
    data: {
      medicalRecordNumber: string;
      id: string;
    }
  ) {
    const pasien = state.list.find((p) => p.noRm === data.medicalRecordNumber);

    const idx = pasien.activeProblems.findIndex((d) => d.id === data.id);
    pasien.activeProblems.splice(idx, 1);
  },
  /**
   * move active problem to inactive
   */
  markProblemsAsResolved(
    state: PasienState,
    data: {
      medicalRecordNumber: string;
      ids: string[];
    }
  ) {
    const pasien = state.list.find((p) => p.noRm === data.medicalRecordNumber);
    for (const id of data.ids) {
      const idx = pasien.activeProblems.findIndex((ap) => ap.id === id);
      const problems = pasien.activeProblems[idx];
      if (problems) {
        pasien.inactiveProblems.push(problems);
        pasien.activeProblems.splice(idx, 1);
      }
    }
  },
  /**
   * move inactive problem to active
   *
   */
  markProblemsAsUnresolved(
    state: PasienState,
    data: {
      medicalRecordNumber: string;
      ids: string[];
    }
  ) {
    const pasien = state.list.find((p) => p.noRm === data.medicalRecordNumber);
    for (const id of data.ids) {
      const idx = pasien.inactiveProblems.findIndex((ap) => ap.id === id);
      const problems = pasien.inactiveProblems[idx];
      if (problems) {
        pasien.activeProblems.push(problems);
        pasien.inactiveProblems.splice(idx, 1);
      }
    }
  },
  /**
   * add allergy
   */
  addAllergy(
    state: PasienState,
    data: {
      medicalRecordNumber: string;
      allergy: { type: string; detail: string; isResolved: boolean };
    }
  ) {
    const pasien = state.list.find((p) => p.noRm === data.medicalRecordNumber);
    if (data.allergy.isResolved) {
      pasien.inactiveAllergies.push({
        id: uuid(),
        detail: data.allergy.detail,
        type: data.allergy.type
      });
      return;
    }

    pasien.activeAllergies.push({
      id: uuid(),
      detail: data.allergy.detail,
      type: data.allergy.type
    });
  },
  /**
   * move active allergy to inactive
   */
  markAllergiesAsResolved(
    state: PasienState,
    data: {
      medicalRecordNumber: string;
      ids: string[];
    }
  ) {
    const pasien = state.list.find((p) => p.noRm === data.medicalRecordNumber);
    for (const id of data.ids) {
      const idx = pasien.activeAllergies.findIndex((ap) => ap.id === id);
      const allergy = pasien.activeAllergies[idx];
      if (allergy) {
        pasien.inactiveAllergies.push(allergy);
        pasien.activeAllergies.splice(idx, 1);
      }
    }
  },
  /**
   * move inactive allergy to active
   */
  markAllergiesAsUnresolved(
    state: PasienState,
    data: {
      medicalRecordNumber: string;
      ids: string[];
    }
  ) {
    const pasien = state.list.find((p) => p.noRm === data.medicalRecordNumber);
    for (const id of data.ids) {
      const idx = pasien.inactiveAllergies.findIndex((ap) => ap.id === id);
      const allergy = pasien.inactiveAllergies[idx];
      if (allergy) {
        pasien.activeAllergies.push(allergy);
        pasien.inactiveAllergies.splice(idx, 1);
      }
    }
  },
  /**
   * add medication
   */
  addMedication(
    state: PasienState,
    data: {
      medicalRecordNumber: string;
      medication: { name: string; isResolved: boolean };
    }
  ) {
    const pasien = state.list.find((p) => p.noRm === data.medicalRecordNumber);
    if (data.medication.isResolved) {
      pasien.inactiveMedications.push(data.medication.name);
      return;
    }

    pasien.activeMedications.push(data.medication.name);
  },
  /**
   * move active medication to inactive
   */
  markMedicationsAsResolved(
    state: PasienState,
    data: {
      medicalRecordNumber: string;
      ids: string[];
    }
  ) {
    const pasien = state.list.find((p) => p.noRm === data.medicalRecordNumber);
    for (const id of data.ids) {
      const idx = pasien.activeMedications.indexOf(id);
      const medication = pasien.activeMedications[idx];
      if (medication) {
        pasien.inactiveMedications.push(medication);
        pasien.activeMedications.splice(idx, 1);
      }
    }
  },
  /**
   * move inactive medication to active
   */
  markMedicationsAsUnresolved(
    state: PasienState,
    data: {
      medicalRecordNumber: string;
      ids: string[];
    }
  ) {
    const pasien = state.list.find((p) => p.noRm === data.medicalRecordNumber);
    for (const id of data.ids) {
      const idx = pasien.inactiveMedications.indexOf(id);
      const medication = pasien.inactiveMedications[idx];
      if (medication) {
        pasien.activeMedications.push(medication);
        pasien.inactiveMedications.splice(idx, 1);
      }
    }
  },
  addPasien(state: PasienState, data: Pasien) {
    state.list.push(data);
  }
};
