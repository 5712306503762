import { readFileSync } from 'fs';
import { resolve as pathResolve } from 'path';
import { NuxtConfig } from '@nuxt/types';
import dotenv from 'dotenv-defaults';

// configure dotenv
dotenv.config({
  defaults: '.env.defaults'
});

// using timestamp to semver version
const createVersion = () => {
  const now = new Date();
  const time = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
  return `${now.getFullYear()}.${now.getMonth()}.${now.getDate()}.${time}`;
};

export const CACHE_VERSION = createVersion();

// setup development TLS certs
let serverKey = '';
let serverCert = '';
if (readFileSync && pathResolve) {
  try {
    serverKey = readFileSync(pathResolve(__dirname, 'server.key'), {
      encoding: 'utf8'
    });
    serverCert = readFileSync(pathResolve(__dirname, 'server.crt'), {
      encoding: 'utf8'
    });
  } catch (err) {}
}

const config: NuxtConfig = {
  // Disable server-side rendering (https://go.nuxtjs.dev/ssr-mode)
  ssr: false,

  // Target (https://go.nuxtjs.dev/config-target)
  target: 'static',
  server: {
    port: 5015,
    host: '0.0.0.0',
    https: {
      key: serverKey,
      cert: serverCert
    }
  },
  generate: {
    fallback: '404.html'
  },
  env: {
    buildNumber: process.env.BUILD_NUMBER || '-',
    iamPortalUrl: process.env.IAM_PORTAL_BASE_URL,
    region: process.env.REGION,
    identityPoolId: process.env.IDENTITY_POOL_ID,
    bucket: process.env.BUCKET,
    directory: process.env.DIRECTORY
  },

  // Global page headers (https://go.nuxtjs.dev/config-head)
  head: {
    title: 'Aplikasi Laporan',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { hid: 'description', name: 'description', content: '' }
    ],
    link: [{ rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' }]
  },

  /**
   * workbox configuration
   * https://pwa.nuxtjs.org/modules/workbox.html
   */
  pwa: {
    workbox: {
      pagesURLPattern: '^((?!(_db|changes)).)*$',
      workboxURL:
        'https://storage.googleapis.com/workbox-cdn/releases/4.3.1/workbox-sw.js',
      cacheNames: {
        suffix: CACHE_VERSION
      }
    }
  },
  // Customize the progress-bar color
  loading: { color: '#00BCD4' },

  // Global CSS (https://go.nuxtjs.dev/config-css)
  css: ['ant-design-vue/dist/antd.css', '~/assets/style/main.scss'],

  // Plugins to run before rendering page (https://go.nuxtjs.dev/config-plugins)
  plugins: [
    '~/plugins/lodash',
    {
      src: '~/plugins/sw-hook',
      ssr: false
    },
    '~/plugins/antd-ui',
    '~/plugins/shortkey',
    '~/plugins/canvas'
  ],

  // Auto import components (https://go.nuxtjs.dev/config-components)
  components: [{ path: '~/components', extensions: ['vue'] }],

  // Modules for dev and build (recommended) (https://go.nuxtjs.dev/config-modules)
  buildModules: [
    // https://go.nuxtjs.dev/typescript
    '@nuxt/typescript-build',
    '@nuxtjs/tailwindcss',
    '~/modules/service-api',
    '@mary-cileungsi/sso-client'
  ],

  /**
   * api configurations
   */
  service: {
    baseUrl: process.env.API_URL,
    graphqlSubscriptionUrl: process.env.SUBSCRIPTION_URL
  },

  /**
   * SSO library configuration
   */
  sso: {
    apiBaseUrl: process.env.IAM_BASE_URL,
    portalBaseUrl: process.env.IAM_PORTAL_BASE_URL
  },

  /**
   * sentry config
   */
  // sentry: {
  //   config: {
  //     environment: process.env.SENTRY_ENV,
  //     release: process.env.SENTRY_RELEASE
  //   },
  //   tracing: {
  //     tracesSampleRate: 0.3,
  //     vueOptions: {
  //       tracing: true,
  //       tracingOptions: {
  //         hooks: ['mount', 'update'],
  //         timeout: 2000,
  //         trackComponents: true
  //       }
  //     },
  //     browserOptions: {
  //       tracingOrigins: [process.env.API_URL, process.env.IAM_BASE_URL]
  //     } as any
  //   }
  // },

  // Modules (https://go.nuxtjs.dev/config-modules)
  modules: [
    // https://go.nuxtjs.dev/pwa
    '@nuxtjs/pwa',
    '@nuxtjs/sentry',
    '@nuxtjs/gtm'
  ],

  // Google Tag Manager Configuration (https://github.com/nuxt-community/gtm-module)
  gtm: {
    id: process.env.GTM_ID
  },

  // Tailwind configuration (https://tailwindcss.nuxtjs.org/options/)
  tailwindcss: {
    config: {
      important: true
    }
  },

  // Build Configuration (https://go.nuxtjs.dev/config-build)
  build: {
    /**
     * You can extend webpack config here
     *
     */
    extend(config, { isClient }) {
      // Extend only webpack config for client-bundle
      if (isClient) {
        config.devtool = '#source-map';
      }
      config.node = {
        fs: 'empty'
      };
      if (
        config.module.rules.find((r) => {
          if (!r.loader) {
            return false;
          }
          return /graphql/.test(r.loader as string);
        })
      ) {
        return;
      }
      config.module.rules.push({
        test: /\.(graphql|gql)$/,
        loader: 'graphql-tag/loader'
      });
    }
  }
};

export default config;
