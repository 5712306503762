import { v4 as uuid } from 'uuid';
import {
  random,
  name,
  date,
  address,
  phone,
  company
} from 'faker/locale/id_ID';
import { DateTime } from 'luxon';
import { randomArray } from './helper';
import { JenisKelamin, Pasien, Status, CPPT } from '~/store/pasien';
import { Kunjungan, StatusKunjungan } from '~/store/kunjungan';
import { Dokter } from '~/store/dokter';
import { Unit } from '~/operation';

export function fakePasien(noRm?: string): Pasien {
  return {
    id: uuid(),
    nama: name.findName(),
    noRm: noRm || '00' + random.number(100000).toString(),
    tanggalLahir: DateTime.fromJSDate(
      date.between('1990-1-1', '2000-1-1')
    ).toJSDate(),
    jenisKelamin: random.arrayElement([JenisKelamin.L, JenisKelamin.P]),
    alamat: address.streetAddress(),
    kabupatenKota: address.city(),
    kecamatan: address.state(),
    kelurahan: address.stateAbbr(),
    namaKeluargaDekat: name.findName(),
    noHp: phone.phoneNumber('+628# #### ####'),
    ...fakeMedicalRecord()
  };
}

export function fakePasiens(): Pasien[] {
  return randomArray(fakePasien, 20, 15);
}

export function fakeKunjungan(noRm?: string): Kunjungan {
  const status = random.arrayElement([
    StatusKunjungan.Terdaftar,
    StatusKunjungan.Finalisasi
  ]);
  const pasien = fakePasien(noRm);
  return {
    id: uuid(),
    namaPasien: pasien.nama,
    waktuKunjungan: date.recent(),
    waktuFinalisasi:
      status === StatusKunjungan.Finalisasi ? date.recent() : null,
    pasien,
    status,
    namaPerusahaan: company.companyName(),
    jaminan: [random.arrayElement(['Pribadi', 'BPJS'])],
    kelas: [random.arrayElement(['Poli', 'Kelas I', 'Kelas II', 'Kelas III'])],
    pasienStatus: random.arrayElement([Status.Baru, Status.Lama]),
    kasusStatus: random.arrayElement([Status.Baru, Status.Lama]),
    unit: random.arrayElement([Unit.Poli, Unit.Ugd]),
    dpjp: random.arrayElement(fakeDefaultDokters()),
    njm: ''
  };
}

export function fakeKunjungans(pasiens: Pasien[]): Kunjungan[] {
  const kunjungans: Kunjungan[] = [];
  for (const p of pasiens) {
    kunjungans.push(fakeKunjungan(p.noRm));
  }
  return kunjungans;
}

export function fakeDokter(): Dokter {
  return {
    id: uuid(),
    nama: 'dr. ' + name.findName()
  };
}

export function fakeDokters(): Dokter[] {
  return randomArray(fakeDokter, 10, 5);
}

export function fakeDefaultDokters(): Dokter[] {
  return [
    {
      id: 'dr0',
      nama: 'dr. ' + 'Fabian'
    },
    {
      id: 'dr1',
      nama: 'dr. ' + 'Simbolon'
    },
    {
      id: 'dr2',
      nama: 'dr. ' + 'Kokom'
    },
    {
      id: 'dr3',
      nama: 'dr. ' + 'Mulyadi'
    },
    {
      id: 'dr4',
      nama: 'dr. ' + 'Iriawan'
    },
    {
      id: 'dr5',
      nama: 'dr. ' + 'Susi'
    },
    {
      id: 'dr6',
      nama: 'dr. ' + 'Wawan'
    },
    {
      id: 'dr7',
      nama: 'dr. ' + 'Tukul'
    }
  ];
}

export function fakeMedicalRecord(): Partial<Pasien> {
  return {
    activeProblems: [
      {
        id: 'E86.0',
        code: 'E86.0',
        name: 'Dehydration'
      },
      {
        id: 'J41.0',
        code: 'J41.0',
        name: 'Simple chronic bronchitis'
      }
    ],
    inactiveProblems: [
      {
        id: 'J02.0',
        code: 'J02.0',
        name: 'Streptococcal pharyngitis'
      },
      {
        id: 'K02.7',
        code: 'K02.7',
        name: 'Dental root caries'
      },
      {
        id: 'M54.5',
        code: 'M54.5',
        name: 'Low back pain'
      }
    ],
    activeAllergies: [
      {
        id: 'Serbuk Sari - ',
        type: 'Serbuk Sari',
        detail: ''
      },
      {
        id: 'Antibiotik - blabla',
        type: 'Antibiotik',
        detail: 'blabla'
      },
      {
        id: 'Antibiotik - obat obat',
        type: 'Antibiotik',
        detail: 'obat obat'
      }
    ],
    inactiveAllergies: [
      {
        id: 'Makanan - Udang',
        type: 'Makanan',
        detail: 'Udang'
      },
      {
        id: 'Udara Dingin - ',
        type: 'Udara Dingin',
        detail: ''
      }
    ],
    activeMedications: ['Omeprazole', 'Obat Tensi', 'Obat kolesterol'],
    inactiveMedications: ['Obat patah tulang', 'Obat alergi'],
    cpptHistory: randomArray(fakeCppt, 10, 5)
  };
}

export function fakeCppt(): CPPT {
  return {
    id: uuid(),
    admissionDate: date.recent(),
    dischargeDate: date.recent(),
    dpjp: name.findName()
  };
}

export interface Diagnosis {
  id: string;
  code: string;
  name: string;
}

export function getDiagnosis(): Diagnosis[] {
  return [
    {
      id: 'J02.0',
      code: 'J02.0',
      name: 'Streptococcal pharyngitis'
    },
    {
      id: 'K02.7',
      code: 'K02.7',
      name: 'Dental root caries'
    },
    {
      id: 'M54.5',
      code: 'M54.5',
      name: 'Low back pain'
    },
    {
      id: 'E86.0',
      code: 'E86.0',
      name: 'Dehydration'
    },
    {
      id: 'J41.0',
      code: 'J41.0',
      name: 'Simple chronic bronchitis'
    },
    {
      id: 'A00.0',
      code: 'A00.0',
      name: 'Cholera due to Vibrio cholerae 01, biovar cholerae'
    },
    {
      id: 'A00.1',
      code: 'A00.1',
      name: 'Cholera due to Vibrio cholerae 01, biovar eltor'
    },
    { id: 'A00.9', code: 'A00.9', name: 'Cholera, unspecified' },
    { id: 'A01.0', code: 'A01.0', name: 'Typhoid fever' },
    { id: 'A01.1', code: 'A01.1', name: 'Paratyphoid fever A' },
    { id: 'A01.2', code: 'A01.2', name: 'Paratyphoid fever B' },
    { id: 'A01.3', code: 'A01.3', name: 'Paratyphoid fever C' },
    { id: 'A01.4', code: 'A01.4', name: 'Paratyphoid fever, unspecified' },
    { id: 'A02.0', code: 'A02.0', name: 'Salmonella enteritis' },
    { id: 'A02.1', code: 'A02.1', name: 'Salmonella septicaemia' },
    { id: 'A02.2', code: 'A02.2', name: 'Localized salmonella infections' },
    {
      id: 'A02.8',
      code: 'A02.8',
      name: 'Other specified salmonella infections'
    },
    { id: 'A02.9', code: 'A02.9', name: 'Salmonella infection, unspecified' },
    {
      id: 'A03.0',
      code: 'A03.0',
      name: 'Shigellosis due to Shigella dysenteriae'
    },
    {
      id: 'A03.1',
      code: 'A03.1',
      name: 'Shigellosis due to Shigella flexneri'
    },
    { id: 'A03.2', code: 'A03.2', name: 'Shigellosis due to Shigella boydii' },
    { id: 'A03.3', code: 'A03.3', name: 'Shigellosis due to Shigella sonnei' },
    { id: 'A03.8', code: 'A03.8', name: 'Other shigellosis' },
    { id: 'A03.9', code: 'A03.9', name: 'Shigellosis, unspecified' },
    {
      id: 'A04.0',
      code: 'A04.0',
      name: 'Enteropathogenic Escherichia coli infection'
    },
    {
      id: 'A04.1',
      code: 'A04.1',
      name: 'Enterotoxigenic Escherichia coli infection'
    },
    {
      id: 'A04.2',
      code: 'A04.2',
      name: 'Enteroinvasive Escherichia coli infection'
    },
    {
      id: 'A04.3',
      code: 'A04.3',
      name: 'Enterohaemorrhagic Escherichia coli infection'
    },
    {
      id: 'A04.4',
      code: 'A04.4',
      name: 'Other intestinal Escherichia coli infections'
    },
    { id: 'A04.5', code: 'A04.5', name: 'Campylobacter enteritis' },
    {
      id: 'A04.6',
      code: 'A04.6',
      name: 'Enteritis due to Yersinia enterocolitica'
    },
    {
      id: 'A04.7',
      code: 'A04.7',
      name: 'Enterocolitis due to Clostridium difficile'
    },
    {
      id: 'A04.8',
      code: 'A04.8',
      name: 'Other specified bacterial intestinal infections'
    },
    {
      id: 'A04.9',
      code: 'A04.9',
      name: 'Bacterial intestinal infection, unspecified'
    },
    {
      id: 'A05.0',
      code: 'A05.0',
      name: 'Foodborne staphylococcal intoxication'
    },
    { id: 'A05.1', code: 'A05.1', name: 'Botulism' },
    {
      id: 'A05.2',
      code: 'A05.2',
      name: 'Foodborne Clostridium perfringens [Clostridium welchii] intoxication'
    },
    {
      id: 'A05.3',
      code: 'A05.3',
      name: 'Foodborne Vibrio parahaemolyticus intoxication'
    },
    {
      id: 'A05.4',
      code: 'A05.4',
      name: 'Foodborne Bacillus cereus intoxication'
    },
    {
      id: 'A05.8',
      code: 'A05.8',
      name: 'Other specified bacterial foodborne intoxications'
    },
    {
      id: 'A05.9',
      code: 'A05.9',
      name: 'Bacterial foodborne intoxication, unspecified'
    },
    { id: 'A06.0', code: 'A06.0', name: 'Acute amoebic dysentery' },
    { id: 'A06.1', code: 'A06.1', name: 'Chronic intestinal amoebiasis' },
    { id: 'A06.2', code: 'A06.2', name: 'Amoebic nondysenteric colitis' },
    { id: 'A06.3', code: 'A06.3', name: 'Amoeboma of intestine' },
    { id: 'A06.4', code: 'A06.4', name: 'Amoebic liver abscess' },
    { id: 'A06.5', code: 'A06.5', name: 'Amoebic lung abscess' },
    { id: 'A06.6', code: 'A06.6', name: 'Amoebic brain abscess' },
    { id: 'A06.7', code: 'A06.7', name: 'Cutaneous amoebiasis' },
    { id: 'A06.8', code: 'A06.8', name: 'Amoebic infection of other sites' },
    { id: 'A06.9', code: 'A06.9', name: 'Amoebiasis, unspecified' },
    { id: 'A07.0', code: 'A07.0', name: 'Balantidiasis' },
    { id: 'A07.1', code: 'A07.1', name: 'Giardiasis [lambliasis]' },
    { id: 'A07.2', code: 'A07.2', name: 'Cryptosporidiosis' },
    { id: 'A07.3', code: 'A07.3', name: 'Isosporiasis' },
    {
      id: 'A07.8',
      code: 'A07.8',
      name: 'Other specified protozoal intestinal diseases'
    },
    {
      id: 'A07.9',
      code: 'A07.9',
      name: 'Protozoal intestinal disease, unspecified'
    },
    { id: 'A08.0', code: 'A08.0', name: 'Rotaviral enteritis' },
    {
      id: 'A08.1',
      code: 'A08.1',
      name: 'Acute gastroenteropathy due to Norwalk agent'
    },
    { id: 'A08.2', code: 'A08.2', name: 'Adenoviral enteritis' },
    { id: 'A08.3', code: 'A08.3', name: 'Other viral enteritis' },
    {
      id: 'A08.4',
      code: 'A08.4',
      name: 'Viral intestinal infection, unspecified'
    },
    {
      id: 'A08.5',
      code: 'A08.5',
      name: 'Other specified intestinal infections'
    },
    {
      id: 'A09',
      code: 'A09',
      name: 'Diarrhoea and gastroenteritis of presumed infectious origin'
    },
    {
      id: 'A09.9',
      code: 'A09.9',
      name: 'Gastroenteritis and colitis of unspecified origin'
    }
  ];
}

export function getAllergyTypes() {
  return ['Makanan', 'Serbuk Sari', 'Udara Dingin', 'Antibiotik'];
}
