import { Dokter } from './dokter';
import { Unit, Pasien, Status } from '~/operation';

export enum KunjunganType {
  Obgyn = 'obgyn',
  Bedah = 'bedah',
  NonBedah = 'non-bedah'
}

export enum StatusKunjungan {
  Terdaftar = 'Terdaftar',
  Finalisasi = 'Finalisasi',
  Selesai = 'Selesai'
}

export interface RegisterOk {
  id?: string;
  waktuOrder: Date;
  noRm: string;
  namaPasien?: string;
  pasien?: Pasien;
  status?: StatusKunjungan;
  namaPerusahaan?: string;
  jaminan?: string[];
  kelas?: string[];
  pasienStatus?: Status;
  kasusStatus?: Status;
  unit?: Unit;
  dpjp?: Dokter;
  njm?: string;
}

export interface KunjunganState {
  unit: Unit;
  dokter: string;
  noRm: string;
}

export function state(): KunjunganState {
  return {
    unit: null,
    dokter: '',
    noRm: ''
  };
}

export const mutations = {
  /**
   * change Unit
   */
  changeUnit(state: KunjunganState, unit: Unit): KunjunganState {
    state.unit = unit;
    return state;
  },
  /**
   * change dokter
   */
  changeDokter(state: KunjunganState, dokterId: string): KunjunganState {
    state.dokter = dokterId;
    return state;
  },
  /**
   * change noRm
   */
  changeNoRm(state: KunjunganState, noRm: string): KunjunganState {
    state.noRm = noRm;
    return state;
  }
};
