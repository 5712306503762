import { random } from 'faker';
import { delay } from 'bluebird';
import { DokumenRekamMedis, DokumenRekamMedisData, Unit } from '~/operation';
export interface FormEntry {
  id: string;
  templateId: string;
  name: string;
  title: string;
  value: any;
  lastUpdatedAt?: Date;
  lastSavedAt?: Date;
}

export interface FormTemplate {
  id: string;
  name: string;
  category: string;
  props: any;
}

export interface ActiveForm {
  id: string;
  templateId: string;
  name: string;
  title: string;
  props: any;
  lastSavedAt?: Date;
}

export interface FormByCategory {
  name: string;
  forms: FormTemplate[];
}

export interface LocalForm {
  lastUpdatedAt: Date;
  payload: any;
  initialPayload?: any;
  cancelled?: boolean;
}

export interface Pagination {
  total: number;
  current: number;
}

export function randomArray<T>(
  iterator: () => T,
  maxLength: number = 11,
  minLength: number = 1
): T[] {
  const length = random.number({ min: minLength, max: maxLength });
  const values: T[] = [];
  for (let i = 0; i < length; i++) {
    values.push(iterator());
    if (values.includes(iterator())) {
      values.pop();
      i--;
    }
  }
  return values;
}

export function mapFormEntryToRekamMedis(
  form: FormEntry,
  pasienId: number,
  kunjunganId: number,
  unit: Unit,
  value = null
): DokumenRekamMedisData {
  let payload = JSON.stringify(form?.value);
  if (value) {
    payload = JSON.stringify(value);
  }
  return {
    id: form?.id,
    idPasien: pasienId,
    idKunjungan: kunjunganId,
    unit,
    idForm: form?.templateId,
    nama: form?.name,
    judul: form?.title,
    kategori: '',
    payload
  };
}

export function mapRekamMedisToFormEntry(form: DokumenRekamMedis): FormEntry {
  if (!form) {
    return;
  }
  return {
    id: form?.id,
    templateId: form?.idForm,
    name: form?.nama,
    title: form?.judul,
    value: JSON.parse(form?.payload),
    lastUpdatedAt: form?.lastUpdatedAt,
    lastSavedAt: new Date(form?.lastUpdatedAt)
  };
}

export function getFormIds(unit: Unit) {
  let ids = [];
  if (unit === Unit.Ugd) {
    ids = [
      '66',
      '89',
      '67',
      '135',
      '137',
      '84',
      '115',
      '90',
      '117',
      '110',
      '138',
      '128',
      '129',
      '111',
      '35',
      '17',
      '22',
      '29',
      '37',
      '38',
      '36',
      '34',
      '52',
      '50',
      '40',
      '58',
      '41',
      '42',
      '105',
      '30',
      '104',
      '31',
      '81',
      '32',
      '130',
      '108',
      '139',
      '140',
      '134',
      '59',
      '106',
      '15',
      '133',
      '132',
      '131',
      '12',
      '126',
      '11',
      '63',
      '46',
      '62',
      '103',
      '60',
      '8',
      '9',
      '180',
      '181'
    ];
  }

  if (unit === Unit.Ok) {
    ids = [
      '117',
      '90',
      '124',
      '128',
      '27',
      '111',
      '33',
      '22',
      '19',
      '73',
      '20',
      '132',
      '131',
      '12',
      '126',
      '76',
      '80',
      '130',
      '108',
      '8',
      '9',
      '146',
      '181'
    ];
  }
  if (unit === Unit.Vk) {
    ids = [
      '117',
      '90',
      '128',
      '33',
      '111',
      '17',
      '22',
      '73',
      '118',
      '124',
      '126',
      '76',
      '62',
      '80',
      '130',
      '108',
      '181'
    ];
  }
  if (unit === Unit.Poli) {
    ids = [
      '43',
      '136',
      '128',
      '126',
      '133',
      '132',
      '131',
      '16',
      '14',
      '17',
      '44',
      '12',
      '32',
      '23',
      '18',
      '117',
      '8',
      '9',
      '116',
      '28',
      '138',
      '129',
      '105',
      '108',
      '22',
      '47',
      '106',
      '35',
      '143',
      '41',
      '42',
      '34',
      '58',
      '81',
      '59',
      '110',
      '61',
      '181',
      '142',
      '182',
      '183',
      '184',
      '185',
      '186',
      '187',
      '109'
    ];
  }
  if (unit === Unit.RawatInap) {
    ids = [
      '1',
      '4',
      '8',
      '9',
      '10',
      '12',
      '15',
      '17',
      '22',
      '23',
      '24',
      '25',
      '29',
      '30',
      '31',
      '33',
      '46',
      '59',
      '60',
      '62',
      '67',
      '68',
      '69',
      '70',
      '72',
      '76',
      '77',
      '78',
      '79',
      '82',
      '83',
      '85',
      '86',
      '87',
      '88',
      '90',
      '91',
      '92',
      '93',
      '94',
      '95',
      '96',
      '97',
      '98',
      '99',
      '100',
      '101',
      '102',
      '103',
      '104',
      '105',
      '106',
      '107',
      '108',
      '110',
      '111',
      '112',
      '113',
      '116',
      '117',
      '120',
      '122',
      '123',
      '125',
      '126',
      '128',
      '129',
      '130',
      '132',
      '133',
      '138',
      '164',
      '149',
      '150',
      '151',
      '152',
      '153',
      '154',
      '155',
      '180',
      '181'
    ];
  }
  if (unit === Unit.MultiUnit) {
    for (let i = 0; i < 187; i++) {
      const id = i + 1;
      ids.push(id.toString());
    }
  }
  return ids;
}

export function sortEntries(entries: FormEntry[] = [], sortedBy = 'name') {
  if (!entries || entries.length < 2) {
    return entries;
  }
  const ent = Object.assign([], entries);
  return ent.sort((a, b) => (a[sortedBy] > b[sortedBy] ? 1 : -1));
}

export async function printLaporan(elementId: string = 'printarea') {
  // Get HTML to print from element
  const basePrtHtml = document.getElementById(elementId).firstChild;
  const prtHtml = basePrtHtml.cloneNode(true);

  // Get all stylesheets HTML
  let stylesHtml = '';
  for (const node of [
    ...document.querySelectorAll('link[rel="stylesheet"], style')
  ]) {
    stylesHtml += node.outerHTML;
  }

  // Open the print window
  const WinPrint = window.open(
    '',
    '',
    'left=0,top=0,width=1000,height=1000,toolbar=0,scrollbars=0,status=0'
  );

  WinPrint.document.write(`<!DOCTYPE html>
  <html>
    <head>
      ${stylesHtml}
      <style type="text/css" media="print">
        * {
          -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
          color-adjust: exact !important;  /*Firefox*/
        }
    </style>
    </head>
    <body class="print-layout">
      <div class="header">
        <div class="flex">
          <div class="header-img px-2 w-20 h-20"></div>
          <div class="uppercase font-bold pl-2" style="margin: auto 0">
            <div class="text-lg">Rumah Sakit</div>
            <div class="text-xl">Mary Cileungsi Hijau</div>
            <div class="text-2xs"> Jl. Raya Narogog KM. 23, Cileungsi, Kabupaten Bogor 16820 </div>
            <div class="text-2xs"> Tlp. (021) 824922222 (Hunting) </div>
          </div>
        </div>
      </div>
      <div class="content history-section" id="form-printer-content">
      </div>
    </body>
  </html>`);

  // append selected form to container
  const contentContainer = WinPrint.document.getElementById(
    'form-printer-content'
  );
  contentContainer.appendChild(prtHtml);

  const canvasesSource = document.querySelectorAll('#printarea canvas');
  const canvases = contentContainer.querySelectorAll('canvas');
  for (const [i, canvas] of canvases.entries()) {
    const canvasImg = document.createElement('img');
    canvasImg.setAttribute(
      'src',
      (canvasesSource[i] as HTMLCanvasElement).toDataURL()
    );
    // canvas.parentNode.replaceChild(canvasImg, canvas);
    if (canvas.parentNode.previousSibling.previousSibling) {
      canvas.parentNode.parentNode.insertBefore(
        canvasImg,
        canvas.parentNode.previousSibling.previousSibling
      );
    } else {
      canvas.parentNode.parentNode.insertBefore(
        canvasImg,
        canvas.parentNode.parentNode.firstChild
      );
    }
    canvas.parentNode.removeChild(canvas);
  }

  const textareaSource = contentContainer.querySelectorAll('textarea');
  for (const ta of textareaSource) {
    const textarea = ta as HTMLTextAreaElement;
    const textLength = textarea.textLength;
    textarea.style.height = textLength * 2 + 'px';
    textarea.style.maxHeight = textLength * 4 + 'px';
  }

  WinPrint.document.close();
  WinPrint.focus();
  await delay(750);
  WinPrint.print();
  WinPrint.close();
}

export function downloadLinkCsv(
  stringCsv: string,
  pageTitle: string = 'Laporan-Mary-Cileungsi'
) {
  const blob = new Blob(['\uFEFF', stringCsv]);
  const encodedUri = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  let filename: string = pageTitle + new Date().toLocaleDateString() + '.csv';
  filename = filename.replace(' ', '_');
  link.setAttribute('download', filename);
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
}

export type tableHeader = {
  dataIndex?: string;
  key?: string;
  title?: string;
  scopedSlots?: any;
  width?: number;
  height?: number;
  fixed?: string;
  sorter?: boolean;
  children?: tableHeader[];
  value?: any;
  customRender?: Function;
};

export function deleteFixedDimension(
  tobeDelete: string[] = ['fixed'],
  headers: tableHeader[]
): tableHeader[] {
  // const tobeDelete = ['fixed']; // 'width','height','fixed'
  const newHeader: tableHeader[] = [];
  headers.map((nhd) => {
    const hp = Object.assign({}, nhd);
    if (hp.children) {
      hp.children = [].concat(hp.children);
    }
    tobeDelete.forEach((td) => delete hp[td]);
    if (hp.children) {
      hp.children.forEach((hpc) => {
        tobeDelete.forEach((td) => delete hpc[td]);
      });
    }
    newHeader.push(hp);
  });
  return newHeader;
}

export function stringUmurInMonth(umur: string) {
  /** format is '1 Thn 20 Bln 4 Hr'
   * assume 1 month is 30 days
   * should we consider use 30 31 days & 'kabisat' ? */
  const splitted = umur ? umur.split(' ') : [];
  const year = splitted[0] ? Number(splitted[0]) : 0;
  const month = splitted[2] ? Number(splitted[2]) : 0;
  const days = splitted[4] ? Number(splitted[4]) : 0;

  return year * 12 + month + days / 30;
}
