import { Dokter } from './dokter';
import { Pasien, Status } from './pasien';
import { KelasUnit, StatusPemeriksaan, Unit } from '~/operation';

export enum KunjunganType {
  Obgyn = 'obgyn',
  Bedah = 'bedah',
  NonBedah = 'non-bedah'
}

export enum StatusKunjungan {
  Terdaftar = 'Terdaftar',
  Finalisasi = 'Finalisasi',
  Selesai = 'Selesai'
}

export interface Kunjungan {
  id?: string;
  namaPasien?: string;
  waktuKunjungan?: Date;
  waktuFinalisasi?: Date;
  pasien?: Pasien;
  status?: StatusKunjungan;
  namaPerusahaan?: string;
  jaminan?: string[];
  kelas?: string[];
  pasienStatus?: Status;
  kasusStatus?: Status;
  unit?: Unit;
  dpjp?: Dokter;
  njm?: string;
}

export interface KunjunganState {
  unit: Unit;
  dokter: string;
  noRm: string;
  kelasUnit: KelasUnit;
  statusPemeriksaan: StatusPemeriksaan;
  finalisasiUnit: Unit;
  finalisasiDokter: string;
  finalisasiNoRm: string;
  finalisasiKelasUnit: KelasUnit;
  finalisasiStatusPemeriksaan: StatusPemeriksaan;
  riwayatNoRm: string;
}

export function state(): KunjunganState {
  return {
    unit: null,
    dokter: '',
    noRm: '',
    kelasUnit: null,
    statusPemeriksaan: null,
    finalisasiUnit: null,
    finalisasiDokter: '',
    finalisasiNoRm: '',
    finalisasiKelasUnit: null,
    finalisasiStatusPemeriksaan: null,
    riwayatNoRm: ''
  };
}

export const mutations = {
  /**
   * change Unit
   */
  changeUnit(state: KunjunganState, unit: Unit): KunjunganState {
    state.unit = unit;
    return state;
  },
  /**
   * change dokter
   */
  changeDokter(state: KunjunganState, dokterId: string): KunjunganState {
    state.dokter = dokterId;
    return state;
  },
  /**
   * change noRm
   */
  changeNoRm(state: KunjunganState, noRm: string): KunjunganState {
    state.noRm = noRm;
    return state;
  },
  /**
   * change Kelas Unit
   */
  changeKelasUnit(state: KunjunganState, unit: KelasUnit): KunjunganState {
    state.kelasUnit = unit;
    return state;
  },
  /**
   * change Status Pemeriksaan
   */
  changeStatusPemeriksaan(
    state: KunjunganState,
    status: StatusPemeriksaan
  ): KunjunganState {
    state.statusPemeriksaan = status;
    return state;
  },
  /**
   * change Finalisasi Unit
   */
  changeFinalisasiUnit(state: KunjunganState, unit: Unit): KunjunganState {
    state.finalisasiUnit = unit;
    return state;
  },
  /**
   * change Finalisasi dokter
   */
  changeFinalisasiDokter(
    state: KunjunganState,
    dokterId: string
  ): KunjunganState {
    state.finalisasiDokter = dokterId;
    return state;
  },
  /**
   * change Finalisasi noRm
   */
  changeFinalisasiNoRm(state: KunjunganState, noRm: string): KunjunganState {
    state.finalisasiNoRm = noRm;
    return state;
  },
  /**
   * change Finalisasi Kelas Unit
   */
  changeFinalisasiKelasUnit(
    state: KunjunganState,
    unit: KelasUnit
  ): KunjunganState {
    state.finalisasiKelasUnit = unit;
    return state;
  },
  /**
   * change Finalisasi Status Pemeriksaan
   */
  changeFinalisasiStatusPemeriksaan(
    state: KunjunganState,
    status: StatusPemeriksaan
  ): KunjunganState {
    state.finalisasiStatusPemeriksaan = status;
    return state;
  },
  /**
   * change Riwayat noRm
   */
  changeRiwayatNoRm(state: KunjunganState, noRm: string): KunjunganState {
    state.riwayatNoRm = noRm;
    return state;
  }
};
