import { fakeDefaultDokters, fakeDokters } from '~/lib/mock';

export interface DokterState {
  list: Dokter[];
}

export interface Dokter {
  id?: string;
  nama: string;
}

export interface DokterData {
  id?: string;
  nama: string;
}

export function state(): DokterState {
  return {
    list: [...fakeDokters(), ...fakeDefaultDokters()]
  };
}

export const mutations = {
  add(state: DokterState, data: DokterData[]) {
    state.list.push(...data);
  }
};
