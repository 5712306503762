import { Middleware } from '@nuxt/types';

const authenticated: Middleware = (ctx) => {
  try {
    const ok = ctx.$sso.isAuthenticated();
    if (!ok) {
      return ctx.$sso.login();
    }
  } catch (err) {
    ctx.$sso.login();
  }
};

export default authenticated;
