export interface DashboardState {
  title: string;
  breadcrumbs: Breadcrumb[];
  collapsed: boolean;
}

export interface Breadcrumb {
  text: string;
  disabled: boolean;
  icon?: string;
  to?: string;
}

/**
 * Get default state of dashboard
 *
 * @export
 * @returns
 */
export function state(): DashboardState {
  return {
    title: 'Dashboard Fasilitas Kesehatan',
    breadcrumbs: [rootBreadcrumb],
    collapsed: false
  };
}

const rootBreadcrumb: Breadcrumb = {
  icon: 'home',
  text: '',
  disabled: true,
  to: '/'
};

export const mutations = {
  /**
   * Change dashboard title
   *
   * @param {DashboardState} state
   * @param {string} title
   */
  changeTitle(state: DashboardState, title: string) {
    state.title = title;
  },

  /**
   * Change breadcrumbs state
   *
   * @param {DashboardState} state
   * @param {Breadcrumb[]} items
   */
  changeBreadcrumbs(state: DashboardState, items: Breadcrumb[]) {
    const root = [rootBreadcrumb];
    state.breadcrumbs = root.concat(items);
  },
  /**
   * toggle sidebar collapse status
   */
  toggleCollapsed(state: DashboardState, status: boolean) {
    state.collapsed = status;
  }
};
